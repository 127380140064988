import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../context/Enviroment";
import jwt_decode from 'jwt-decode';
// Validate Form and handle Login
export const handelLogin = async (values, setLoadEmail, navigate) => {
  const url = `${BASE_URL}/Auth/login`;
  try {
    setLoadEmail(true);
    const { data } = await axios.post(url, values);

    if (data.StatusCode === "Error") {
      toast.error("Error With UserName or Password");
      setLoadEmail(false);
    } else if (data.StatusCode === "OTP Sent") {
      // //console.log(data.StatusCode);
      toast.success("OTP Sent");
      navigate("/admin/otp", { state: { phoneNumber: values.phoneNumber } });
    } else if (data.IsSuccess === true) {
      // //console.log(data.IsSuccess);
      toast.success("Success");
      localStorage.setItem("userToken", data.Data.token);
      localStorage.setItem("userName", data.Data?.userFullName);
      localStorage.setItem("userPhoto", data.Data?.photo);
      
      // Decode and log the tokenInfo
      const tokenInfo = jwt_decode(data.Data.token);
      // //console.log('Decoded Token Info:', tokenInfo);
      localStorage.setItem("role", tokenInfo.UserType);
      localStorage.setItem("adminRole", tokenInfo.role);

      
      navigate("/");
    } else {
      setLoadEmail(false);
    }
  } catch (error) {
    console.error(error.response.data);
    if (error.response.data.StatusCode === "Error") {
      toast.error(error.response.data.Message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    setLoadEmail(false);
  }
};
