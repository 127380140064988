import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LogoSvg from "../../../assets/svg/LogoSvg";
import "./login.scss";
import { Alert, CircularProgress } from "@mui/material";
import { validateLoginForm } from "../Services/Validation";
import { handelLogin } from "../Services/AuthService";
import Img from "../../../assets/Images/logo.png";
import SidebarLogo from "../../../assets/Images/logo/logo-dark.png";
import lock from "../../../assets/Images/login/lock.png";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { VendersContext } from "../../../context/Store";
import { ProgressSpinner } from "primereact/progressspinner";

function Login() {
  let { isLang, setIsLang } = useContext(VendersContext);
  const langChange = localStorage.getItem("langChange");

  const { t, i18n } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loadEmail, setLoadEmail] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    setLoadEmail(true);

    const loginData = {
      phoneNumber: phoneNumber,
      password: password
    };

    // Call the login function with the data
    await handelLogin(loginData, setLoadEmail, navigate);
  };

  return (
    <div className="row w-100 login">
      <div className="col-md-3 h-100 d-flex text-center justify-content-center align-items-center login-sidebar">
        <img src={SidebarLogo} width="220px" height="110px" />
      </div>

      <div className="col-md-9  h-100  d-flex flex-column justify-content-center align-items-center ">
        <div className="  d-flex flex-column justify-content-center align-items-center login-container ">
          <div
            dir={langChange == "en" ? "ltr" : "rtl"}
            className="d-flex flex-column text-center align-items-center gap-3 mb-4"
          >
            <img src={lock} width="100px" height="120px" />
            <span>{t("loginMessage")}</span>
          </div>

          <form dir={langChange == "en" ? "ltr" : "rtl"} onSubmit={handleSubmit} className="d-flex flex-column gap-3">
            <div>
              <h6 className="form-label text-main" htmlFor="phoneNumber">
                {t("phoneNumber")}*
              </h6>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                className="login-input px-2"
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </div>
            <div>
              <h6 className="form-label text-main" htmlFor="password" dir={langChange == "en" ? "ltr" : "rtl"}>
                {t("password")}*
              </h6>
              <input
                type="password"
                id="password"
                value={password}
                className="login-input  px-2"
                onChange={e => setPassword(e.target.value)}
              />
              <Link to={"/admin/forget-password"}>
                <h6 className=" forgetPassword mt-2 secondary-text">{t("forgetPassword")}</h6>
              </Link>
            </div>
            <div>
              <Button
                className="rounded-3 login-btn justify-content-center"
                severity="info"
                type="submit"
                onClick={handleSubmit}
              >
                {loadEmail ? (
                  <ProgressSpinner style={{ width: "30px", height: "30px" }} strokeWidth="6" animationDuration=".5s" />
                ) : (
                  t("loginSubmit")
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
