import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';

const PopupComponent = ({ visible, onClose, header, content }) => {
  return (
    <Dialog
      visible={visible}
      onHide={onClose}
      // onBlur={onClose}
      header={header}
      maximizable
      resizable
      style={{ width: '500px' , height:'616 px' }} 
      >
      {content}
    </Dialog>
  );
};

export default PopupComponent;
