import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Table } from "react-bootstrap";
import ExcelExporter from "../../ExcelExporter/ExcelExporter";
import { BASE_URL } from "../../context/Enviroment";
import { Dialog } from "primereact/dialog";
import CustomPagination from "../../Components/Paginator/Paginator";
import PopupComponent from "../../Components/Popup/ContentPopup";
import SharePricesPopupContent from "./SharePricesPopupContent";
import { getAllChildCustomersApi, getAllPricesApi, getBrandsApi } from "../auth/Services/ContentAPIService";

export default function PriccesView() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCustomersChild, setSelectedCustomersChild] = useState(null);
  const userRole = localStorage.getItem("role");
  const [showButton, setShowButton] = useState(userRole !== "Agent");

  //get all brands
  const [brands, setBrands] = useState();

  const getBrands = async () => {
    setIsLoading(true);

    try {
      const brandData = await getBrandsApi();
      setBrands(brandData);
    } catch (error) {
      console.error("An error occurred while fetching brands:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //get all customers
  const [customersChild, setCustomersChild] = useState([]);

  const getAllCustomers = async () => {
    try {
      const childCustomersData = await getAllChildCustomersApi();
      setCustomersChild(childCustomersData);
    } catch (error) {
      console.error("An error occurred while fetching child customers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // get all prices

  const [pricesData, setPricesData] = useState([]);
  const getAllPrices = async selectedChildId => {
    setIsLoading(true);
    try {
      const prices = await getAllPricesApi(selectedBrand, selectedChildId);
      setPricesData(prices);
    } catch (error) {
      console.error("An error occurred while fetching prices:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchClick = () => {
    getAllPrices(selectedCustomersChild);
  };

  useEffect(() => {
    getBrands();
    getAllCustomers();
  }, []);

  const handleBrandChange = e => {
    setSelectedBrand(e.value);
  };
  const { t, i18n } = useTranslation();

  //handle excel sheet to export
  const columnsToExport = pricesData.map(item => ({
    [t("productNumber")]: item.ProductCode,
    [t("ProductName")]: item.ProductName,
    [t("cost")]: item.ParentCost,
    [t("sellPrice")]: item.SellPrice,
    [t("endUserPrice")]: item.EndUserPrice
  }));

  //edit price

  const [editPriceId, setEditPriceId] = useState(null);
  const [editPriceValue, setEditPriceValue] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);

  const handleEditPrice = priceId => {
    setEditPriceId(priceId);
    setEditPriceValue("");
    setDisplayDialog(true);
  };

  const handleCopyPrices = async selectedProductId => {
    try {
      const updatedPrice = {
        productId: selectedProductId,
        price: editPriceValue,
        IsActive: true
      };
      const userToken = localStorage.getItem("userToken");

      const response = await fetch(`${BASE_URL}/api/Prices/AddUpdateCopyPrices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify({ productPrices: [updatedPrice] })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Update the pricesData state with the updated data received from backend
      const updatedPricesData = await response.json();
      setPricesData(updatedPricesData.Data);
      // //console.log(updatedPricesData.Data);

      // Close the popup and reset the state variables
      setDisplayDialog(false);
      setEditPriceId(null);
      setEditPriceValue("");
    } catch (error) {
      console.error("Error updating price:", error);
    }
  };

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentReportData = pricesData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(pricesData.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle popup
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const openPopup = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  //handle cancel click
  const handleCancelClick = () => {
    setSelectedCustomersChild("");
    setSelectedBrand("");
  };
  return (
    <div className="report-container ">
      <h3 className="text-main m-3 px-2 report-head">{t("pricesView")}</h3>

      <div className="card  mb-4 custom-card m-3 p-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="ml-2">
              <div className="d-flex gap-5 align-items-center p-4">
                <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                  <span className="m-2 h6">{t("selectBrand")} :</span>
                  <Dropdown
                    className="tergetReport-dropdown w-100"
                    value={selectedBrand}
                    options={brands}
                    onChange={handleBrandChange}
                    optionLabel="Name"
                    placeholder={t("choose")}
                  />
                </div>

                <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                  <span className="m-2 h6">{t("clients")} :</span>
                  <Dropdown
                    className="tergetReport-dropdown w-100"
                    value={selectedCustomersChild}
                    options={customersChild?.map(customer => ({
                      label: customer?.User?.UserFullName,
                      value: customer?.Id
                    }))}
                    onChange={e => {
                      const selectedChildId = e.value;
                      setSelectedCustomersChild(selectedChildId);
                      // getAllPrices(selectedChildId);
                    }}
                    placeholder={t("choose")}
                    filter
                    filterBy="label"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns mb-4 ">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 ">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2"
                onClick={handleSearchClick}
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff" }}
                className="rounded-2 cancel-btn w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />

          {showButton && (
            <Button
              label={t("sharePrices")}
              style={{ border: "none", backgroundColor: "#007bff", color: "#fff", width: "190px", height: "50px" }}
              className="rounded-2 btn main-bg"
              onClick={openPopup}
            />
          )}
          <PopupComponent visible={isPopupVisible} onClose={closePopup} content={<SharePricesPopupContent />} />
        </div>

        <Table striped hover>
          <thead className="custom-thead">
            <tr className="text-center">
              <th className="text-light">{t("productNumber")}</th>
              <th className="text-light">{t("ProductName")}</th>
              {showButton && <th className="text-light">{t("cost")}</th>}

              <th className="text-light">{t("sellPrice")}</th>
              <th className="text-light">{t("endUserPrice")}</th>
            </tr>
          </thead>
          <tbody>
            {currentReportData.map((rowData, index) => (
              <tr key={index}>
                <td width="80px" className="text-center">
                  {rowData.ProductCode}
                </td>
                <td width="80px" className="text-center">
                  {rowData.ProductName}
                </td>
                {showButton && (
                  <td width="80px" className="text-center">
                    {rowData.ParentCost}
                  </td>
                )}
                <td width="80px" className="text-center">
                  {rowData.SellPrice}
                </td>
                <td width="80px" className="text-center">
                  {rowData.EndUserPrice}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          indexOfLastRow={indexOfLastRow}
          reportData={pricesData}
        />
        <Dialog visible={displayDialog} onHide={() => setDisplayDialog(false)} header="Edit Price">
          <div>
            <label htmlFor="editPriceInput">Price:</label>
            <input id="editPriceInput" type="text" value={editPriceValue} onChange={e => setEditPriceValue(e.target.value)} />
            <button onClick={handleCopyPrices}>Save</button>
            <button onClick={() => setDisplayDialog(false)}>Cancel</button>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
