// PDFExportComponent.js

import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const PDFExportComponent = ({ id, trigger, onExportStart, onExportEnd }) => {
  const exportPDF = () => {
    onExportStart();
    setTimeout(() => {
      const input = document.getElementById("fullDataTable");
      html2canvas(input).then(canvas => {
        onExportEnd();
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const pageHeight = 295;
        let heightLeft = imgHeight;

        const pdf = new jsPDF("p", "mm");
        let position = 0;

        pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("download.pdf");
      });
    }, 1000);
  };

  return <div>{React.cloneElement(trigger, { onClick: exportPDF })}</div>;
};

export default PDFExportComponent;
