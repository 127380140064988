import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { getAllChildCustomersApi, getUsersApi } from "../../auth/Services/ContentAPIService";
import { Dropdown } from "primereact/dropdown";
import { BASE_URL } from "../../../context/Enviroment";
import { Button } from "primereact/button";
import Loading from "../../../Components/Loading/Loading";
import { Table } from "react-bootstrap";
import NoDataAvailable from "../../../Components/NoDataAvailable/NoDataAvailable";
import Swal from "sweetalert2";

export default function TransactionsLogsAdmin() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomersChild, setSelectedCustomersChild] = useState(null);
  const [customersChild, setCustomersChild] = useState([]);
  const [reportData, setReportData] = useState([]);

  //get all child customers
  const getAllChildCustomers = async () => {
    try {
      const childCustomersData = await getAllChildCustomersApi();
      setCustomersChild(childCustomersData);
    } catch (error) {
      console.error("An error occurred while fetching child customers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  //Api request
  const fetchData = async selectedUserId => {
    setIsLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      const requestBody = {
        userId: selectedUserId || null
      };

      if (fromDate && toDate) {
        requestBody.fromDate = formatDate(fromDate);
        requestBody.toDate = formatDate(toDate);
      }

      const response = await fetch(`${BASE_URL}/Report/GetTransactionLogsAdmin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setReportData(data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error("Error details:", error.message, error.status);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllChildCustomers();
  }, []);

  const handleUserChange = e => {
    const selectedChildId = e.value;
    setSelectedCustomersChild(selectedChildId);
  };

  //handle search click
  const handleSearchClick = () => {
    if (selectedCustomersChild) {
      fetchData(String(selectedCustomersChild));
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a customer!"
      });
    }
  };
  return (
    <div className="report-container ">
      <h3 className="text-main m-3 report-head">{t("transactionsAdmin")}</h3>
      <div className="card mb-4 custom-card m-3 p-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="h6">{t("from")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={fromDate}
                    onChange={e => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="ml-2 h6">{t("to")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={toDate}
                    onChange={e => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="ml-2 h6">{t("clients")}: </span>

                <Dropdown
                  className="tergetReport-dropdown w-100"
                  value={selectedCustomersChild}
                  options={customersChild?.map(customer => ({
                    label: customer?.User?.UserFullName,
                    value: customer?.Id
                  }))}
                  onChange={e => {
                    const selectedChildId = e.value;
                    setSelectedCustomersChild(selectedChildId);
                  }}
                  placeholder={t("choose")}
                  filter
                  filterBy="label"
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 mb-2">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn"
                onClick={handleSearchClick}
              />
              <Button
                // onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff", width: "117px", height: "50px" }}
                className="rounded-2 "
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive-sm" style={{ overflowX: "auto" }}>
              <Table striped hover>
                <thead className="custom-thead">
                  <tr>
                    <th className="text-light ml-2">Balance Before</th>
                    <th className="text-light ml-2">Balance After</th>
                    {/* <th className="text-light ml-2">Transaction Log Type</th> */}
                    <th className="text-light ml-2">Amount</th>
                    <th className="text-light ml-2">Is Positive</th>
                  </tr>
                </thead>
                <tbody className="text-center transactionlogs-table">
                  {reportData.map((item, index) => (
                    <tr key={index}>
                      {/* Access only the first element of the TransactionInfos array */}
                      <td>{item.TransactionInfos[0].BalanceBefore}</td>
                      <td>{item.TransactionInfos[0].BalanceAfter}</td>
                      {/* <td>{item.TransactionInfos[0].TransactionLogType}</td> */}
                      <td>{item.TransactionInfos[0].Amount}</td>
                      <td>{item.TransactionInfos[0].IsPositive ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
