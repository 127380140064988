import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import { useTranslation } from "react-i18next";
import { Table, Pagination } from "react-bootstrap";
import Loading from "../../../Components/Loading/Loading";
import { BASE_URL } from "../../../context/Enviroment";
import PopupMessage from "../../../Components/AlertPopup/Popup";
import "./wholesaleBankcommision.scss";
import CustomPagination from "../../../Components/Paginator/Paginator";
import { fetchProductsForBrandApi, getAllChildCustomersApi, getBrandsApi } from "../../auth/Services/ContentAPIService";

function GetTargetMobile() {
  const { t, i18n } = useTranslation();
  const [reportData, setReportData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [customersChild, setCustomersChild] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  //Api request
  const fetchData = async () => {
    setIsLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      const reportType = selectedOption === "اجمالي" ? 1 : 2;

      const requestBody = {
        reportType: reportType
      };

      if (fromDate && toDate) {
        requestBody.fromDate = formatDate(fromDate);
        requestBody.toDate = formatDate(toDate);
      }
      requestBody.brandId = selectedBrand || null;
      requestBody.parentId = selectedCustomersParents || null;
      requestBody.childId = selectedCustomersChild || null;
      requestBody.productId = selectedProduct || null;

      const response = await fetch(`${BASE_URL}/Report/ReportWholeSaleWithBankCommission`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setReportData(data.Data);
      //console.log(data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDate(dateString) {
    //console.log("Original date string:", dateString);

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error("Invalid date string:", dateString);
      return "";
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  //get all brands
  const [brands, setBrands] = useState();

  const getBrands = async () => {
    setIsLoading(true);

    try {
      const brandData = await getBrandsApi();
      setBrands(brandData);
    } catch (error) {
      console.error("An error occurred while fetching brands:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // get all products
  const [products, setProducts] = useState();
  const fetchProductsForBrand = async brandId => {
    setIsLoading(true);
    const requestBody = JSON.stringify({
      brandId: String(brandId)
    });
    try {
      const productOptions = await fetchProductsForBrandApi(brandId);
      setProducts(productOptions);
    } catch (error) {
      console.error("An error occurred while fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //get all child customers
  const getAllChildCustomers = async () => {
    try {
      const childCustomersData = await getAllChildCustomersApi();
      setCustomersChild(childCustomersData);
    } catch (error) {
      console.error("An error occurred while fetching child customers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBrands();
    getAllChildCustomers();
  }, []);

  const options = [{ label: "اجمالي", value: "اجمالي" }, { label: "تفصيلي", value: "تفصيلي" }];
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCustomersParents, setSelectedCustomersParents] = useState(null);
  const [selectedCustomersChild, setSelectedCustomersChild] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleBrandChange = e => {
    const selectedBrandId = e.value?.Id;
    setSelectedBrand(selectedBrandId);

    fetchProductsForBrand(selectedBrandId);
  };

  const [showProductNameColumn, setShowProductNameColumn] = useState(true);

  const handleSearchClick = () => {
    if (selectedOption === null) {
      setShowPopup(true);
    } else {
      setShowProductNameColumn(selectedOption === "تفصيلي");
      fetchData();
    }
  };
  const onHide = () => {
    setShowPopup(false);
  };

  const handleOptionChange = e => {
    setSelectedOption(e.value);
  };

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const bankcommision = reportData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(reportData.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle excel sheet to export

  const columnsToExport = reportData.map(item => {
    if (selectedOption === "تفصيلي") {
      return {
        [t("productNumber")]: item.productNumber,
        [t("ProductName")]: item.ProductName,
        [t("PaymentSource")]: item.PaymentSource,
        [t("TotalCost")]: item.TotalCost,
        [t("TotalPosPrice")]: item.TotalPosPrice,
        [t("TotalEndUserPrice")]: item.TotalEndUserPrice,
        [t("TotalCompanyProfit")]: item.TotalCompanyProfit,
        [t("TotalPosProfit")]: item.TotalPosProfit,
        [t("TotalBankCommission")]: item.TotalBankCommission,
        [t("TotalBankCommissionVat")]: item.TotalBankCommissionVat,
        [t("TotalRetrivedFromBanck")]: item.TotalRetrivedFromBanck
      };
    } else {
      return {
        [t("PaymentSource")]: item.PaymentSource,
        [t("TotalCost")]: item.TotalCost,
        [t("TotalPosPrice")]: item.TotalPosPrice,
        [t("TotalEndUserPrice")]: item.TotalEndUserPrice,
        [t("TotalCompanyProfit")]: item.TotalCompanyProfit,
        [t("TotalPosProfit")]: item.TotalPosProfit,
        [t("TotalBankCommission")]: item.TotalBankCommission,
        [t("TotalBankCommissionVat")]: item.TotalBankCommissionVat,
        [t("TotalRetrivedFromBanck")]: item.TotalRetrivedFromBanck
      };
    }
  });

  return (
    <div className="report-container">
      <h3 className="text-main m-3 report-head">{t("reports")}</h3>
      <div className="card mb-4 custom-card  d-flex flex-row ">
        <div className="col-md-9 px-1">
          <div className="d-flex justify-content-between align-items-center p-4">
            <div className="custom-flex-container">
              <div className="custom-dropdown d-flex flex-column">
                <span className="h6">{t("reportType")}: </span>
                <Dropdown
                  className="tergetReport-dropdown"
                  value={selectedOption}
                  options={options}
                  onChange={handleOptionChange}
                  placeholder={t("choose")}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <span className="h6">{t("from")}: </span>
              <div className="p-input-icon-left">
                <i className="pi pi-calendar calendar-icon text-main" />
                <Calendar
                  className="tergetReport-calendar"
                  value={fromDate}
                  onChange={e => setFromDate(e.value)}
                  dateFormat="dd/mm/yy"
                  placeholder={t("choose")}
                />
              </div>
            </div>

            <div className="d-flex flex-column">
              <span className="ml-2 h6">{t("to")}: </span>
              <div className="p-input-icon-left">
                <i className="pi pi-calendar calendar-icon text-main" />
                <Calendar
                  className="tergetReport-calendar"
                  value={toDate}
                  onChange={e => setToDate(e.value)}
                  dateFormat="dd/mm/yy"
                  placeholder={t("choose")}
                />
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="d-flex gap-5 align-items-center p-4">
              <div>
                <span className="m-2 h6">{t("selectBrand")} :</span>

                <Dropdown
                  className="tergetReport-dropdown"
                  value={selectedBrand}
                  options={brands}
                  onChange={e => {
                    const selectedBrandId = e.value;
                    setSelectedBrand(selectedBrandId);
                    fetchProductsForBrand(selectedBrandId);
                  }}
                  optionLabel="Name"
                  placeholder={t("choose")}
                />
              </div>
              <div>
                <span className="m-2 h6">{t("selectProduct")} : </span>
                <Dropdown
                  className="tergetReport-dropdown"
                  value={selectedProduct}
                  options={products}
                  onChange={e => setSelectedProduct(e.value)}
                  optionLabel="label"
                  placeholder={t("choose")}
                />
              </div>

              <div>
                <span className="m-2 h6">{t("clients")} :</span>
                <Dropdown
                  className="tergetReport-dropdown"
                  value={selectedCustomersChild}
                  options={customersChild?.map(customer => ({
                    label: customer?.User?.UserFullName,
                    value: customer?.Id
                  }))}
                  onChange={e => {
                    const selectedChildId = e.value;
                    setSelectedCustomersChild(selectedChildId);
                    fetchData(selectedChildId);
                  }}
                  placeholder={t("choose")}
                  filter
                  filterBy="label"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mx-5 targetBtn-container">
          <Button
            label={t("search")}
            style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
            className="rounded-2 btn"
            onClick={handleSearchClick}
          />
        </div>
      </div>
      <PopupMessage visible={showPopup} onHide={onHide} message={t("selectOptionMessagePopup")} closable={true} onBlur={true} />

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive-sm" style={{ overflowX: "auto" }}>
              <Table striped hover>
                <thead className="custom-thead">
                  <tr>
                    {showProductNameColumn && selectedOption === "تفصيلي" && (
                      <>
                        <th className="text-light ml-2" width="100px">
                          {t("productNumber")}
                        </th>
                        <th className="text-light ml-2" width="100px">
                          {t("ProductName")}
                        </th>
                      </>
                    )}

                    <th className="text-light ml-2" width="100px">
                      {t("PaymentSource")}
                    </th>
                    <th className="text-light ml-2" width="100px">
                      {t("TotalCost")}
                    </th>
                    <th className="text-light ml-2" width="100px">
                      {t("TotalPosPrice")}
                    </th>

                    <th className="text-light ml-2" width="100px">
                      {t("TotalEndUserPrice")}
                    </th>
                    <th className="text-light ml-2" width="100px">
                      {t("TotalCompanyProfit")}
                    </th>

                    <th className="text-light ml-2" width="100px">
                      {t("TotalPosProfit")}
                    </th>
                    <th className="text-light ml-2" width="100px">
                      {t("TotalBankCommission")}
                    </th>
                    <th className="text-light ml-2" width="100px">
                      {t("TotalBankCommissionVat")}
                    </th>
                    <th className="text-light ml-2" width="100px">
                      {t("TotalRetrivedFromBanck")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center transactionlogs-table">
                  {bankcommision.map((item, index) => (
                    <tr key={index}>
                      {showProductNameColumn && selectedOption === "تفصيلي" && (
                        <>
                          <td width="130px" className="ml-4">
                            {item.ProductId}
                          </td>
                          <td width="130px" className="ml-4">
                            {item.ProductName}
                          </td>
                        </>
                      )}
                      <td width="130px" className="ml-4">
                        {item.PaymentSource}
                      </td>
                      <td width="130px" className="ml-4">
                        {item.TotalCost}
                      </td>
                      <td width="130px" className="ml-4">
                        {item.TotalPosPrice}
                      </td>

                      <td width="130px" className="ml-4">
                        {item.TotalEndUserPrice}
                      </td>
                      <td width="130px" className="ml-4">
                        {item.TotalCompanyProfit}
                      </td>

                      <td width="130px" className="ml-4">
                        {item.TotalPosProfit}
                      </td>
                      <td width="130px" className="ml-4">
                        {item.TotalBankCommission}
                      </td>
                      <td width="130px" className="ml-4">
                        {item.TotalBankCommissionVat}
                      </td>
                      <td width="130px" className="ml-4">
                        {item.TotalRetrivedFromBanck}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfLastRow={indexOfLastRow}
                reportData={reportData}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GetTargetMobile;
