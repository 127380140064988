import Vendor from "../Layout/Vendor";
import Navber from "./../Components/Navber/Navber";
import Error from "./../Components/NotFound/Error";
import Sildebar from "./../Components/Sidebar/Sildebar";
import Auth from "./../Layout/Auth";
import Contact from "./../Pages/Contact/Contact";
import Dashboard from "./../Pages/Dashbord/Dashbord";
import Login from "./../Pages/auth/Login/Login";
import Home from "./../Pages/Home/Home";
import Otp from "../Pages/auth/OTP/Otp";
import GetTargetMobile from "../Pages/Reports/GetTargetMobile/GetTargetMobile";
import Test from "../Pages/Reports/Test";
import Profile from "../Components/Profile/Profile";
import PricesSettings from "../Pages/Prices/PricesSettings";
import PriccesView from "../Pages/Prices/PriccesView";
import Customers from "../Pages/Customers/Customers";
import ViewBalanceTransaction from "../Pages/Customers/ViewBalanceTransaction";
import Transactions from "../Pages/Customers/Transactions";
import ForgetPassword from "../Pages/auth/ForgetPassword/ForgetPassword";
import ForgetOTP from "../Pages/auth/ForgetPassword/ForgetOTP";
import ResetPAssword from "../Pages/auth/ResetPAssword/ResetPAssword";
import ScanQRCode from "../Pages/auth/ScanQRCode";
import TransactionLogs from "../Pages/Reports/POS-Transaction-Logs/TransactionLogs";
import wholesaleBankcommision from "../Pages/Reports/wholesale-bankcommision/wholesaleBankcommision";
import AvailableReport from "../Pages/Reports/Available-Report/AvailableReport";
import BankCommissionWithDistPrice from "../Pages/Reports/BankCommissionWithDistPrice/BankCommissionWithDistPrice";
import Bills from "../Pages/Bills/Bills";
import TransactionsLogsAdmin from "../Pages/Reports/TransactionsLogsAdmin/TransactionsLogsAdmin";
import TransactionsLogsAgent from "../Pages/Reports/TransactionsLogsAgent/TransactionsLogsAgent";
import TotalReportPos from "../Pages/POS/Reports/TotalReportPos/TotalReportPos";
import DetailedReportPos from "../Pages/POS/Reports/DetailedReportPos/DetailedReportPos";
import PurchaseList from "../Pages/Purchase/PurchaseList";
import ViewCartProducts from "../Pages/Purchase/Cart/ViewCartProducts";
import POSBills from "../Pages/Purchase/Bills/POSBills";
import ActionSetting from "../Pages/ActionSetting/ActionSetting";
const Component = {
  // Layout
  Vendor,
  Auth,
  Dashboard,
  Contact,
  Login,
  Navber,
  Sildebar,
  Error,
  Otp,
  GetTargetMobile,
  Test,
  Profile,
  PricesSettings,
  PriccesView,
  Customers,
  ViewBalanceTransaction,
  Transactions,
  ForgetPassword,
  ForgetOTP,
  ResetPAssword,
  ScanQRCode,
  TransactionLogs,
  wholesaleBankcommision,
  AvailableReport,
  BankCommissionWithDistPrice,
  Bills,
  TransactionsLogsAdmin,
  TransactionsLogsAgent,
  Home,
  TotalReportPos,
  DetailedReportPos,
  PurchaseList,
  ViewCartProducts,
  POSBills,
  ActionSetting
};

export default Component;
