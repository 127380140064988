import React, { useRef, useState } from "react";
import SidebarLogo from "../../../assets/Images/logo/logo-dark.png";
import { useTranslation } from "react-i18next";
import forgetImg from "../../../assets/Images/login/lock1.png";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from "primereact/inputnumber";
import { BASE_URL } from "../../../context/Enviroment";
import axios from "axios";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Loading from "../../../Components/Loading/Loading";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const langChange = localStorage.getItem("langChange");
  const [loadEmail, setLoadEmail] = useState(false);

  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(151351);

  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;
  //console.log(phoneNumber);
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    setIsLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");
      const headers = {
        Authorization: `Bearer ${userToken}`
      };
      const response = await axios.post(
        `${BASE_URL}/Auth/ResetPassword`,
        {
          phoneNumber: phoneNumber,
          password: password
        },
        {
          headers: headers // Pass the headers object in the request
        }
      );

      if (response.data.IsSuccess === true) {
        navigate("/admin/login");
        //console.log("ddds");
      } else {
        setError("Invalid OTP. Please try again.");
        toast.current.show({ severity: "error", detail: response.data.Message, life: 3000 });
      }
      setIsLoading(false);
    } catch (error) {
      toast.current.show({ severity: "error", detail: "Wrong OTP", life: 3000 });
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <div className="row w-100 login  forget-password-container">
      <Toast ref={toast} position="bottom-center" />
      <div className="col-md-9  h-100  d-flex flex-column justify-content-center align-items-center ">
      {isLoading ? (
            <Loading />
          ) : (
        <div className="  d-flex flex-column justify-content-center align-items-center login-container ">
          <div
            dir={langChange == "en" ? "ltr" : "rtl"}
            className="d-flex flex-column text-center align-items-center gap-3 mb-4"
          >
            <img src={forgetImg} width="130px" height="125px" />
            <span className="h4">{t("resetContent")}</span>
            <span />
          </div>

          <form dir={langChange == "en" ? "ltr" : "rtl"} onSubmit={handleSubmit} className="d-flex flex-column gap-3">
            <div>
              <h6 className="form-label text-main" htmlFor="password" dir={langChange == "en" ? "ltr" : "rtl"}>
                {t("password")}*
              </h6>
              <input
                type="password"
                id="password"
                value={password}
                className="login-input  px-2"
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div>
              <Button
                className="rounded-3 login-btn justify-content-center"
                severity="info"
                type="submit"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <ProgressSpinner style={{ width: "30px", height: "30px" }} strokeWidth="6" animationDuration=".5s" />
                ) : (
                  t("submit")
                )}
              </Button>
            </div>
          </form>
        </div>
                  )}

      </div>
      <div className="col-md-3 h-100 d-flex text-center justify-content-center align-items-center login-sidebar">
        <img src={SidebarLogo} width="220px" height="110px" />
      </div>
    </div>
  );
}
