import React from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player';


export default function Loading() {
  return (


<Player
  autoplay
  loop
  src="https://assets9.lottiefiles.com/packages/lf20_qi4nzlpv.json"
  style={{ height: '700px', width: '700px' }}
>
</Player>    )
}
