import React, { useRef, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import "./Otp.scss";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../context/Enviroment";
import SidebarLogo from "../../../assets/Images/logo/logo-dark.png";
import otpImg from "../../../assets/Images/login/Subtract.png";
import { useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { InputText } from 'primereact/inputtext';

function Otp() {
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);

  const langChange = localStorage.getItem("langChange");

  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;

  // //console.log(phoneNumber);
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/Auth/VerifyOTP`, {
        params: {
          phoneNumber: phoneNumber,
          code: otp
        }
      });

      if (response.data.StatusCode === "Success") {
        localStorage.setItem("userToken", response.data.Data.token);
        // //console.log(response.data.Data.token);
        navigate("/");

        // //console.log("OTP verified successfully!");
      } else {
        setError("Invalid OTP. Please try again.");
        toast.current.show({ severity: "error", detail: response.data.Message, life: 3000 });
      }
    } catch (error) {
      toast.current.show({ severity: "error", detail: "Wrong OTP", life: 3000 });
    }

    setIsLoading(false);
  };

  const handleResetOTP = async () => {
    const phoneNumber = localStorage.getItem("phone");

    try {
      const response = await axios.get(`${BASE_URL}/Auth/ForgetPassword`, {
        params: {
          phoneNumber: phoneNumber
        }
      });

      if (response.data.StatusCode === "Success") {
        localStorage.setItem("userToken", response.data.Data.token);
        // //console.log(response.data.Data.token);
        navigate("/home");

        // //console.log("OTP verified successfully!");
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred while sending the request:", error);
    }
  };

  return (
    <>
      <Toast ref={toast} position="bottom-center" />

      <div className="row w-100 login">
        <div className="col-md-9  h-100  d-flex flex-column justify-content-center align-items-center ">
          <div className="  d-flex flex-column justify-content-center align-items-center login-container ">
            <div className="d-flex flex-column text-center align-items-center gap-3 mb-4">
              <img src={otpImg} width="110px" height="113px" />
              <span>
                {t("otpMessage")} {phoneNumber}
              </span>
              <span />
            </div>

            <form onSubmit={handleSubmit} className="mb-5 d-flex flex-column align-items-center">
              <div className="form-group d-flex gap-2 otp-input-continer mb-3">
              <InputText                  type="text"
                  value={otp}
                  onChange={e => setOtp(e.target.value)}
                  maxLength={6}
                  className=" form-control"
                />
                {error && <div className="error">{error}</div>}
              </div>

              <div className="d-flex gap-2 mb-5">
                <span>{t("resendOtp")}</span>
                <span className="secondary-text" onClick={handleResetOTP}>
                  {t("resendOTPBtn")}{" "}
                </span>
              </div>

              <Button
                className="rounded-3 login-btn justify-content-center"
                severity="info"
                type="submit"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <ProgressSpinner style={{ width: "30px", height: "30px" }} strokeWidth="6" animationDuration=".5s" />
                ) : (
                  t("otpBtn")
                )}
              </Button>
            </form>
          </div>
        </div>
        <div className="col-md-3 h-100 d-flex text-center justify-content-center align-items-center login-sidebar">
          <img src={SidebarLogo} width="220px" height="110px" />
        </div>
      </div>
    </>
  );
}

export default Otp;
