import React from 'react'; 
import './Dashboard.scss';
function Dashboard() {
 

    return (
        <>

            <div className="welcome__page   bg-body  " style={{ display: 'flex ', justifyContent: 'center', alignItems: 'center' }}>
                <div className="title_Dynamic shadow-lg rounded-3">
                    <h3> Dashboard  </h3>
                </div>
            </div>

        </>
    )
}

export default Dashboard