import React from "react";
import SidebarLogo from "../../assets/Images/logo/logo-dark.png";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import Loading from "../../Components/Loading/Loading";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import QRCode from "qrcode.react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router";

const totp = require("totp-generator");
export default function ScanQRCode() {
    const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const langChange = localStorage.getItem("langChange");

  const [secret, setSecret] = useState("QDWSM3OYBPGTEVHPB5FKVDM3CSNCWHVK");
  const [enteredOTP, setEnteredOTP] = useState("");
  const [isOTPValid, setIsOTPValid] = useState(false);

  //verify code
  const verifyCode = () => {
    if (!secret) {
      //console.log("Please set a valid secret key.");
      return;
    }

    try {
      const otpValue = totp(secret);
      //console.log("Generated OTP from Server:", otpValue);
      //console.log("Entered OTP:", enteredOTP);

      if (otpValue === enteredOTP) {
        //console.log("Code is valid!");
        navigate("/");

        setIsOTPValid(true);

      } else {
        //console.log("Code is invalid. Please try again.");
        setIsOTPValid(false);
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      setIsOTPValid(false);
    }
  };
  return (
    <div className="row w-100 login">
      <div className="col-md-9  h-100  d-flex flex-column justify-content-center align-items-center ">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="  d-flex flex-column justify-content-center align-items-center login-container ">
            <div
              dir={langChange == "en" ? "ltr" : "rtl"}
              className="d-flex flex-row text-center align-items-center mb-3 justify-content-center gap-2"
            >
              <i className="pi pi-mobile mb-2" style={{ fontSize: "1.5rem" }} />
              <span className="h4">Scan Qr Code</span>

              <span />
            </div>

            <form className="mb-5 d-flex flex-column align-items-center">
              <QRCode value={`otpauth://totp/MyApp?secret=${secret}`} />
              <div className="form-group d-flex gap-2 mt-3  mb-3">
              <input type="text" placeholder="Enter OTP" className="form-control" value={enteredOTP} onChange={e => setEnteredOTP(e.target.value)} />
                {/* <OtpInput
                  value={enteredOTP}
                  onChange={e => setEnteredOTP(e.target.value)}
                  numInputs={6}
                  renderInput={props => <input className="otp-input mb-5" {...props} />}
                /> */}
              </div>
              <Button onClick={verifyCode} className="rounded-3 login-btn justify-content-center" severity="info" type="submit">
                {isLoading ? (
                  <ProgressSpinner style={{ width: "30px", height: "30px" }} strokeWidth="6" animationDuration=".5s" />
                ) : (
                  t("otpBtn")
                )}
              </Button>
              {isOTPValid && <p>Code is valid!</p>}
              {!isOTPValid && <p>Code is invalid. Please try again.</p>}
            </form>
          </div>
        )}
      </div>
      <div className="col-md-3 h-100 d-flex text-center justify-content-center align-items-center login-sidebar">
        <img src={SidebarLogo} width="220px" height="110px" />
      </div>
    </div>
  );
}
