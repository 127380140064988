import React, { useContext, useEffect, useState } from "react";
import "./ViewCartProducts.scss";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { BASE_URL } from "../../../context/Enviroment";
import axios from "axios";
import Swal from "sweetalert2";
import { Dialog } from "primereact/dialog";
import { CartContext } from "../../../context/CartProvider";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";

const ViewCartProducts = ({ selectedCustomerId }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [billData, setBillData] = useState([]);
  const { t } = useTranslation();
  const [displayDialog, setDisplayDialog] = useState(false);
  const { cartCount, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    setSelectedProducts(storedProducts);
  }, []);

  const PayProducts = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const currentLang = localStorage.getItem("langChange") || "en"; 

  
      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }
      const acceptLanguage = currentLang === "ar" ? "ar-US,ar;q=0.9" : "en-US,en;q=0.9";

      const userData = {
        deviceId: "",   
        ip: "",         
        mac: "",        
        location: "",   
        mobileView: null,     
        transactionId: "" 
      };
  
      const payProductsBody = {
        date: new Date().toISOString(),
        PaymentMethod: 1,
        details: selectedProducts.map(product => ({
          productId: product.productId,
          quantity: product.quantity,
          price: product.updatedPrice !== undefined ? product.updatedPrice : product.price
        })),
        isProduction: false,
        PaymentCard: 0,
        userData 
      };
  
      const apiEndpoint = `${BASE_URL}/Invoice/InsertSaleBill`;
  
      const response = await axios.post(apiEndpoint, payProductsBody, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": acceptLanguage,
          Authorization: `Bearer ${userToken}`
        }
      });
  
      if (!response.data.IsSuccess) {
        throw new Error(response.data.Message || "Error occurred in processing payment.");
      }
  
      Swal.fire({
        position: "top-start",
        icon: "success",
        title: "Product Added To Cart",
        showConfirmButton: false,
        timer: 1500
      });
      setDisplayDialog(true);
      setBillData(response.data.Data);
      setSelectedProducts([]);
      localStorage.removeItem("cartProducts");
    } catch (error) {
      console.error("Error processing payment:", error);
      if (error.response && error.response.data && error.response.data.Message) {
        throw new Error(error.response.data.Message);
      } else {
        throw new Error("An error occurred while processing the payment.");
      }
    }
  };
  

  const onHide = () => {
    setDisplayDialog(false);
  };
  const handlePayNowClick = async () => {
    const confirmationResult = await Swal.fire({
      title: t("areYouSure"),
      text: t("areYouSureBody"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#65539a",
      cancelButtonColor: "#E58241",
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "Cancel"
    });
  
    if (confirmationResult.isConfirmed) {
      try {
        const amountValue = calculateTotal(selectedProducts);
        await PayProducts(amountValue, selectedCustomerId, selectedProducts);
        Swal.fire({
          icon: "success",
          title: "Payment successful!",
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        console.error("Error paying for products:", error);
        // Display the error message as is, which should be in the correct language
        Swal.fire({
          icon: "error",
          title: t("error"),
          text: error.message || "An error occurred while processing the payment."
        });
      }
    }
  };
  
  
  
  const calculateTotal = selectedProducts => {
    const total = selectedProducts.reduce((accumulator, product) => {
      const price = product.updatedPrice !== undefined ? product.updatedPrice : product.price;
      return accumulator + product.price * product.quantity;
    }, 0);

    return total;
  };

  const handleQuantityChange = (productId, amount) => {
    const updatedProducts = selectedProducts.map(product => {
      if (product.productId === productId) {
        const newQuantity = Math.max(1, (product.quantity !== undefined ? product.quantity : 0) + amount);
        const newPrice = calculateNewPrice(product.price, newQuantity);
        const updatedProduct = { ...product, quantity: newQuantity };
        updatedProduct.updatedPrice = newPrice;

        return updatedProduct;
      }
      return product;
    });

    setSelectedProducts(updatedProducts);
    localStorage.setItem("cartProducts", JSON.stringify(updatedProducts));
  };

  const handleProductDelete = productId => {
    const updatedProducts = selectedProducts.filter(product => product.productId !== productId);
    setSelectedProducts(updatedProducts);
    localStorage.setItem("cartProducts", JSON.stringify(updatedProducts));
    removeFromCart(productId);
  };

  const calculateNewPrice = (originalPrice, newQuantity) => {
    return originalPrice * newQuantity;
  };
  return (
    <>
      <div className="mx-5">
        <h3 className="text-main m-3 mx-5 report-head">{t("selectedProducts")}</h3>
      </div>
      <div>
        <ul>
          {selectedProducts.map(product => (
            <div className="card d-flex flex-row justify-content-between p-3 mb-3 mx-5" key={product.productId}>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>{product.name}</div>
                <div>
                  <span className="text-main h6">السعر : </span>
                  <span className="h6 text-main">{product.updatedPrice || product.price}</span>
                </div>
                <div className="d-flex flex-row gap-1 mt-3">
                  <Button
                    icon="pi pi-minus"
                    className="h6"
                    onClick={() => handleQuantityChange(product.productId, -1)}
                    style={{ background: "#E58241", color: "#ffff", borderRadius: "50%" }}
                  />
                  <InputText
                    value={Math.max(1, product.quantity !== undefined ? product.quantity : 0)}
                    className="h6"
                    readOnly
                    style={{ width: "60px", textAlign: "center" }}
                  />
                  <Button
                    icon="pi pi-plus"
                    className="h6"
                    onClick={() => handleQuantityChange(product.productId, 1)}
                    style={{ background: "#E58241", color: "#fff", borderRadius: "50%" }}
                  />
                </div>
              </div>
              <div className="d-flex flex-column-reverse mb-4">
                <i
                  className="pi pi-trash text-danger"
                  style={{ fontSize: "1.5rem" }}
                  onClick={() => handleProductDelete(product.productId)}
                />
              </div>

              <div className="d-flex flex-end selectedProduct-img">
                <img src={product.image} alt={product.name} style={{ width: "40%" }} />
              </div>
            </div>
          ))}
        </ul>
      </div>
      <div className="align-items-center d-flex flex-column gap-3 mb-3">
        <div className="text-center">
          <h3 className="text-main">
            {t("total")} : {calculateTotal(selectedProducts)}
          </h3>
        </div>
        <div className="w-50 text-center mx-5 px-5 mb-3">
          <button
            style={{
              border: "none",
              backgroundColor: "#E58241",
              color: "#fff"
            }}
            className={`rounded-2 w-50 btn w-100 mb-2 ${selectedProducts.length === 0 ? "disabled" : ""}`}
            onClick={handlePayNowClick}
            disabled={selectedProducts.length === 0}
          >
            <h5>{t("PayNow")}</h5>
          </button>
        </div>
      </div>

      <Dialog
        visible={displayDialog}
        onHide={onHide}
        header={t("billDetails")}
        modal
        style={{ width: "50vw" }}
        headerStyle={{ textAlign: "center" }}
      >
        {billData && (
          <div className="px-3">
            <div className="d-flex flex-row justify-content-between mb-3">
              <p className="h6">
                {t("billNumber")} : {billData.BillId}
              </p>
              <p className="h6">
                {t("PAYED_AMT")}: {billData.BillTotal}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mb-3">
              <p className="h6">
                {t("totalPaid")} : {billData.UserTotalPaid}
              </p>
              <p className="h6">
                {t("points")} : {billData.UserPoints}
              </p>
            </div>
            {billData?.cards?.map(card => (
              <div key={card.CardId} className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">{card.CardName}</h5>
                  <p className="card-text">Pin Card: {card.PinCard}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ViewCartProducts;
