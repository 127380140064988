import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SharePricesPopupContent.scss";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { BASE_URL } from "../../context/Enviroment";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

export default function SharePricesPopupContent() {
  const toast = useRef(null);

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const [clients, setClients] = useState([]);

  const [customersChild, setCustomersChild] = useState([]);

  const getAllCustomers = async () => {
    try {
      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      const requestBody = {
        isAll: true,
        pageNo: 1,
        haveParent: null,
        pageSize: 10
      };

      const response = await fetch(`${BASE_URL}/CustomerAccount/GetCustomers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCustomersChild(data.Data);
      const filteredClients = data.Data.filter(client => client.UserType !== 1);
      // //console.log(filteredClients);
      setClients(filteredClients);
      // //console.log(data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const optionLabelTemplate = option => option.User.UserFullName;

  //get all prices
  const [pricesData, setPricesData] = useState([]);
  const [selectedCustomersChild, setSelectedCustomersChild] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getAllPrices = async selectedChildId => {
    setIsLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      const pricesRequestBody = {
        childId: selectedChildId,
        isAll: true,
        parentId: null
      };

      const response = await fetch(`${BASE_URL}/Prices/GetAllPrices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(pricesRequestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setPricesData(data?.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllCustomers();
    getAllPrices();
  }, []);

  const [selectedClients, setSelectedClients] = useState([]);

  const handleClientSelection = e => {
    setSelectedClients(e.value);
  };

  // copy prices request
  const sendCopyPrices = () => {
    const apiUrl = `${BASE_URL}/Prices/AddUpdateCopyPrices`;
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      console.error("User token not found in local storage");
      return;
    }
    const data = {
      customersIds: selectedClients.map(client => client.Id),
      productPrices: selectedProduct.map(productId => {
        return {
          productId,
          isActive: true
        };
      })
    };
    // //console.log(selectedClients);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`
    };

    // Make the POST request
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("API request failed");
        }
      })
      .then(responseData => {
        // //console.log(responseData);
        if (responseData.Data) {
          toast.current.show({ severity: "success", detail: t("successullyCopied"), life: 3000 });
        } else {
          toast.current.show({ severity: "error", detail: t("failedCopied"), life: 3000 });
        }
      })
      .catch(error => {
        console.error(error);
        toast.error(t("failedCopied"));
      });
  };

  return (
    <div dir="rtl" className="report-container">
      <Toast ref={toast} position="top-center" />

      {/* popup header */}
      <div className=" d-flex text-center align-items center flex-column popup-header mb-4">
        <i className="pi pi-arrow-right-arrow-left mb-3" style={{ fontSize: "1.5rem" }} />
        <h3 className="text-center"> {t("sharePrices")} </h3>
      </div>

      <hr />

      {/* from client */}
      <div>
        <h6>{t("transferFromClient")}</h6>
        <div className="card flex justify-content-center mb-3 ">
          <Dropdown
            className="w-full md:w-20rem"
            value={selectedCustomersChild}
            options={customersChild?.map(customer => ({
              label: customer?.User?.UserFullName,
              value: customer?.Id
            }))}
            onChange={e => {
              const selectedChildId = e.value;
              setSelectedCustomersChild(selectedChildId);
              getAllPrices(selectedChildId);
            }}
            placeholder={t("choose")}
          />
        </div>
        {/* get prices dropdown  */}
        <div>
          <h6>{t("selectCopyPricesProducts")}</h6>
          <div className="card flex justify-content-center mb-3">
            <MultiSelect
              filter
              className="w-full md:w-20rem"
              value={selectedProduct}
              options={pricesData.map(price => ({
                label: price.ProductName,
                value: price.ProductId
              }))}
              onChange={e => {
                const selectedProductId = e.value;
                setSelectedProduct(selectedProductId);
              }}
              placeholder={t("choose")}
            />
          </div>
        </div>
      </div>

      {/* to clients */}
      <div cla>
        <h6>{t("transferToClient")}</h6>
        <div className="card flex justify-content-center mb-4">
          <MultiSelect
            optionLabel={optionLabelTemplate}
            filter
            placeholder="Select Clients"
            value={selectedClients}
            options={clients}
            maxSelectedLabels={3}
            className="w-full md:w-20rem"
            onChange={handleClientSelection}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <Button
          label={t("sharePrices")}
          style={{ border: "none", backgroundColor: "#E58241", color: "#fff" }}
          className="rounded-2 btn text-center align-items-center"
          onClick={sendCopyPrices}
        />
      </div>
    </div>
  );
}
