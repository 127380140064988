import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../../../Components/Loading/Loading";
import ExcelExporter from "../../../../ExcelExporter/ExcelExporter";
import CustomPagination from "../../../../Components/Paginator/Paginator";
import { BASE_URL } from "../../../../context/Enviroment";
import PDFExportComponent from "../../../../Components/PDFExporter/PDFExportComponent ";
import img from "../../../../assets/Images/logo.png";
import { format } from "date-fns";

export default function DetailedReportPos() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [detailedReport, setDetailedReport] = useState([]);
  const [isExporting, setIsExporting] = useState(false);

  const [totalAmount, setTotalAmount] = useState({
    posPrice: 0,
    endUserPrice: 0,
    posProfit: 0,
    TotalCard: 0,
    TotalBankCommission: 0,
    TotalBankCommissionVat: 0,
    totalBankCommissionSum: 0
  });


  const adjustDateForTimezone = (date) => {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  };
  const getDetailedPosReport = async () => {
    setIsLoading(true);

    const apiUrl = `${BASE_URL}/Report/GetUserSalesByTime`;

    const adjustedFromDate = adjustDateForTimezone(fromDate);
    const adjustedToDate = adjustDateForTimezone(toDate);

    const formattedFromDate = adjustedFromDate.toISOString();
    const formattedToDate = adjustedToDate.toISOString();

    const requestData = {
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      reportType: "2"
    };

    const userToken = localStorage.getItem("userToken");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        console.error("Error fetching data. Status:", response.status);
        return;
      }

      const responseData = await response.json();
      console.log("API Response:", responseData.Data);
      setDetailedReport(responseData.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const formatDate = date => {
    return format(date, "dd/MM/yyyy");
  };
  //handle cancel click
  const handleCancelClick = () => {
    setFromDate("");
    setToDate("");
  };

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const detailedReportData = detailedReport.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(detailedReport.length / rowsPerPage);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle excel sheet to export
  const columnsToExport = detailedReportData.map(item => ({
    [t("serviceName")]: item.BrandName,
    [t("userName")]: item.CustomerName,
    [t("productNumber")]: item.ProductId,
    [t("PaymentSource")]: item.PaymentSource,
    [t("TotalPosPrice")]: item.TotalPosPrice,
    [t("TotalEndUserPrice")]: item.TotalEndUserPrice,
    [t("TotalPosProfit")]: item.TotalPosProfit,
    [t("TotalBankCommission")]: item.TotalBankCommission,
    [t("TotalBankCommissionVat")]: item.TotalBankCommissionVat
  }));

  const calculateTotals = () => {
    let totalPosPrice = 0;
    let totalEndUserPrice = 0;
    let totalPosProfit = 0;
    let TotalCard = 0;
    let TotalBankCommission = 0;
    let TotalBankCommissionVat = 0;

    detailedReport.forEach(item => {
      totalPosPrice += parseFloat(item.TotalPosPrice || 0);
      totalEndUserPrice += parseFloat(item.TotalEndUserPrice || 0);
      totalPosProfit += parseFloat(item.TotalPosProfit || 0);
      TotalCard += parseFloat(item.TotalCard || 0);
      TotalBankCommission += parseFloat(item.TotalBankCommission || 0);
      TotalBankCommissionVat += parseFloat(item.TotalBankCommissionVat || 0);
    });

    const totalBankCommissionSum = TotalBankCommission + TotalBankCommissionVat;

    setTotalAmount({
      posPrice: totalPosPrice.toFixed(2),
      endUserPrice: totalEndUserPrice.toFixed(2),
      posProfit: totalPosProfit.toFixed(2),
      TotalCard: TotalCard,
      TotalBankCommission: TotalBankCommission.toFixed(2),
      TotalBankCommissionVat: TotalBankCommissionVat.toFixed(2),
      totalBankCommissionSum: totalBankCommissionSum.toFixed(2)
    });
  };

  useEffect(
    () => {
      calculateTotals();
    },
    [detailedReport]
  );

  //pdf exporting
  const columns = [
    { title: "Service Name", dataKey: "serviceName" },
    { title: "User Name", dataKey: "CustomerName" },
    { title: "Payment Source", dataKey: "paymentSource" },
    { title: "Card Name", dataKey: "cardName" },
    { title: "Total Card", dataKey: "totalCard" },
    { title: "Total POS Price", dataKey: "totalPosPrice" },
    { title: "Total End User Price", dataKey: "totalEndUserPrice" },
    { title: "Total POS Profit", dataKey: "totalPosProfit" },
    { title: "Total Bank Commission", dataKey: "totalBankCommission" }
  ];

  // Map your data for the PDF export
  const dataForPDF = detailedReport.map(item => ({
    serviceName: item.BrandName,
    userName: item.CustomerName,
    paymentSource: item.PaymentSource,
    cardName: item.ProductName,
    totalCard: item.TotalCard,
    totalPosPrice: item.TotalPosPrice,
    totalEndUserPrice: item.TotalEndUserPrice,
    totalPosProfit: item.TotalPosProfit,
    totalBankCommissionAndVat: (parseFloat(item.TotalBankCommission) + parseFloat(item.TotalBankCommissionVat)).toFixed(2) // Combine the two columns
  }));

  return (
    <>
      <h3 className="text-main m-3 px-2 report-head">{t("detailedReport")}</h3>
      <div className="card p-3 m-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-row gap-3 mb-3 w-100 align-items-center">
                <span className="h6">{t("from")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={fromDate}
                    onChange={e => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    showTime={true}
                    hourFormat="12"
                    timeOnly={false}
                    placeholder={t("choose")}
                  />
                </div>
              </div>

              <div className="custom-dropdown d-flex flex-row gap-3 mb-3 w-100 align-items-center">
                <span className="ml-2 h6">{t("to")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={toDate}
                    onChange={e => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    showTime={true}
                    hourFormat="12"
                    timeOnly={false}
                    placeholder={t("choose")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 mt-0">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2"
                onClick={getDetailedPosReport}
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff" }}
                className="rounded-2 cancel-btn w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card p-3 m-4 mt-4 ">
        <div className="d-flex gap-2 flex-row align-items-center ">
          <div className="d-flex justify-content-between align-items-center p-3">
            <ExcelExporter data={columnsToExport} fileName="report_data" />
          </div>
          <PDFExportComponent
            trigger={
              <Button
                type="button"
                label={t("PDF")}
                className="rounded-3 pdf-button"
                style={{ border: "none" }}
                icon="pi pi-file-pdf"
                rounded
              />
            }
            onExportStart={() => setIsExporting(true)}
            onExportEnd={() => setIsExporting(false)}
            columns={columns}
            data={dataForPDF}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          />
        </div>
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive" id="divToPrint">
              <Table striped hover>
                <thead className="custom-thead">
                  <tr className="text-center">
                    <th className="text-light"> {t("serviceName")}</th>
                    <th className="text-light"> {t("userName")}</th>
                    <th className="text-light"> {t("PaymentSource")}</th>
                    <th className="text-light"> {t("cardName")}</th>
                    <th className="text-light"> {t("TotalCard")}</th>
                    <th className="text-light"> {t("TotalPosPrice")}</th>
                    <th className="text-light"> {t("TotalEndUserPrice")}</th>
                    <th className="text-light"> {t("TotalPosProfit")}</th>
                    <th className="text-light"> {t("TotalBankCommission")}</th>
                    <th className="text-light"> {t("TotalBankCommissionVat")}</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {detailedReportData?.map((dataItem, index) => (
                    <tr key={`${dataItem.BrandName}-${index}`}>
                      <td>{dataItem.BrandName}</td>
                      <td>{dataItem.CustomerName}</td>
                      <td>{dataItem.PaymentSource}</td>
                      <td>{dataItem.ProductName}</td>
                      <td>{dataItem.TotalCard}</td>
                      <td>{dataItem.TotalPosPrice}</td>
                      <td>{dataItem.TotalEndUserPrice}</td>
                      <td>{dataItem.TotalPosProfit}</td>
                      <td>{dataItem.TotalBankCommission}</td>
                      <td>{dataItem.TotalBankCommissionVat}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="text-center">
                  <tr className="h5">
                    <td colSpan="4" />
                    <td>{totalAmount.TotalCard}</td>
                    <td>{totalAmount.posPrice}</td>
                    <td>{totalAmount.endUserPrice}</td>
                    <td>{totalAmount.posProfit}</td>
                    <td>{totalAmount.TotalBankCommission}</td>
                    <td>{totalAmount.TotalBankCommissionVat}</td>
                    <td colSpan="2" />
                  </tr>
                </tfoot>
              </Table>

              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfLastRow={indexOfLastRow}
                reportData={detailedReport}
              />
            </div>
          )}
        </div>
      </div>
      {isExporting && (
        <div id="fullDataTable" className="p-4" style={{ position: "absolute", left: "-1000px" }}>
          <div className="d-flex justify-content-between">
            <div>
              <p className=" h5 secondary-text">شـركة عقد الثـريـا للتجارة</p>
              <p>
                <span>العنوان: </span>
                <span className="h6"> حي العمل شارع العدل الواعظ</span>
              </p>
              <p>
                <span> سجل تجاري : </span>
                <span className="h6">1010325436</span>
              </p>
              <p>
                <span> الهاتف:</span>
                <span className="h6">0112212833</span>
                <span className="h6">-</span>
                <span className="h6">0112212844</span>
              </p>
              <p>
                <span> رقم التسجيل الضريبي : </span>
                <span className="h6">310305072500003</span>
              </p>
            </div>

            <div>
              <img src={img} width="200px" alt="Logo" className=" p-3" />
            </div>
          </div>

          <h4 className="text-main text-center p-3">
            <span> {t("detailedReport")}</span> <span> من الفترة </span>
            <span>{fromDate ? formatDate(fromDate) : "N/A"} </span> <span> الي </span>
            {toDate ? formatDate(toDate) : "N/A"}
          </h4>
          <Table hover>
            <thead className="custom-thead">
              <tr className="text-center">
                <th className="text-light"> {t("serviceName")}</th>
                <th className="text-light"> {t("userName")}</th>
                <th className="text-light"> {t("PaymentSource")}</th>
                <th className="text-light"> {t("cardName")}</th>
                <th className="text-light"> {t("TotalCard")}</th>
                <th className="text-light"> {t("TotalPosPrice")}</th>
                <th className="text-light"> {t("TotalEndUserPrice")}</th>
                <th className="text-light"> {t("TotalPosProfit")}</th>
                <th className="text-light"> {t("TotalBankCommission")}</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {dataForPDF?.map((dataItem, index) => (
                <tr key={`${dataItem.serviceName}-${index}`}>
                  <td>{dataItem.serviceName}</td>
                  <td>{dataItem.userName}</td>
                  <td>{dataItem.paymentSource}</td>
                  <td>{dataItem.cardName}</td>
                  <td>{dataItem.totalCard}</td>
                  <td>{dataItem.totalPosPrice}</td>
                  <td>{dataItem.totalEndUserPrice}</td>
                  <td>{dataItem.totalPosProfit}</td>
                  <td>{dataItem.totalBankCommissionAndVat}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="text-center">
              <tr className="h5">
                <td colSpan="4" />
                <td>{totalAmount.TotalCard}</td>
                <td>{totalAmount.posPrice}</td>
                <td>{totalAmount.endUserPrice}</td>
                <td>{totalAmount.posProfit}</td>
                <td>{totalAmount.totalBankCommissionSum}</td>
                <td colSpan="2" />
              </tr>
            </tfoot>
          </Table>
        </div>
      )}
    </>
  );
}
