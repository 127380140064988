import React, { useState } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { BASE_URL } from "../../../context/Enviroment";
import { Table } from "react-bootstrap";
import Loading from "../../../Components/Loading/Loading";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import CustomPagination from "../../../Components/Paginator/Paginator";
import { useNavigate } from "react-router-dom";

export default function TransactionsLogsAgent() {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [{ label: "NewLeap", value: 1 }, { label: "SurePay", value: 2 }, { label: "interSoft", value: 3 }];
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const selectedLabel = selectedOption ? options.find(option => option.value === selectedOption.value).label : "";
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const sendApiRequest = async () => {
    setIsLoading(true);
  
    const apiUrl = `${BASE_URL}/Report/GetTransactionLogs`;
  
    if (!fromDate || !toDate || !selectedOption) {
      console.error("Please select fromDate, toDate, and reportType");
      return;
    }
  
    const requestData = {
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      reportType: selectedOption.value
    };
  
    const userToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestData)
      });
  
      if (response.status === 401) {
        window.location.href = '/admin/login';
      }
  
      const responseData = await response.json();
  
      if (!response.ok) {
        console.error("API Error:", responseData);
        return;
      }
  
      console.log("API Response:", responseData.Data);
      setReportData(responseData.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  //handle report type option
  const handleOptionChange = selected => {
    setSelectedOption(selected);
  };
  const { t } = useTranslation();
  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(6);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const transactionsLogs = reportData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(reportData.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle excel sheet to export
  const columnsToExport = reportData.map(item => ({
    [t("referenceNumber")]: item.Reference,
    [t("balanceBeforeTransfer")]: item.TransactionInfos[0].BalanceBefore,
    [t("transferBalance")]: item.TransactionInfos[0].Amount,
    [t("balanceAfterTransfer")]: item.TransactionInfos[0].BalanceAfter,
    [t("العملية")]: item.TransactionInfos[0].IsPositive ? "اضافة" : "خصم"
  }));

  return (
    <>
      <h3 className="text-main m-3 px-2 report-head">{t("transactionsLogs")}</h3>
      <div className="card mb-4 custom-card m-3 p-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="h6">{t("reportType")}: </span>
                <Dropdown
                  value={selectedOption}
                  options={options}
                  onChange={handleOptionChange}
                  placeholder={selectedLabel || t("choose")}
                  className="tergetReport-dropdown w-100"
                />
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="h6">{t("from")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={fromDate}
                    onChange={e => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="ml-2 h6">{t("to")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={toDate}
                    onChange={e => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12  mt-md-0 report-btns mb-2">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 mx-3">
              <Button
                onClick={sendApiRequest}
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive p-2">
              <Table striped hover>
                <thead className="custom-thead text-light">
                  <tr>
                    <th className="text-light ">{t("referenceNumber")}</th>
                    <th className="text-light ">{t("balanceBeforeTransfer")}</th>
                    <th className="text-light ">{t("transferBalance")}</th>
                    <th className="text-light ">{t("balanceAfterTransfer")}</th>
                    <th className="text-light ">العملية </th>
                  </tr>
                </thead>
                <tbody className="text-center transactionlogs-table">
                  {transactionsLogs?.map((entry, index) =>
                    entry.TransactionInfos.map((info, infoIndex) => (
                      <tr key={`${index}-${infoIndex}`}>
                        <td>{entry.Reference}</td>
                        <td>{info.BalanceBefore}</td>
                        <td>{info.Amount}</td>
                        <td>{info.BalanceAfter}</td>
                        <td>{info.IsPositive ? "اضافة" : "خصم"}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>

              <div className="text-center">
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  indexOfLastRow={indexOfLastRow}
                  reportData={reportData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
