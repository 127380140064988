import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../context/Enviroment";
import { Table, Pagination } from "react-bootstrap";
import Loading from "../../../Components/Loading/Loading";
import CustomPagination from "../../../Components/Paginator/Paginator";
import { format } from "date-fns";
import "./TransactionLogs.scss";
import PopupMessage from "../../../Components/AlertPopup/Popup";
import { InputText } from "primereact/inputtext";

export default function TransactionLogs() {
  const options = [{ label: "NewLeap", value: 1 }, { label: "SurePay", value: 2 }, { label: "interSoft", value: 3 }];
  const [searchTerm, setSearchTerm] = useState("");

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [reportData, setReportData] = useState([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  //handle report type option
  const handleOptionChange = selected => {
    setSelectedOption(selected);
  };

  //handle search terms
  const [macAddress, setMacAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [auth, setAuth] = useState("");

  const fetchData = async reportType => {
    setIsLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      const requestBody = {
        reportType: reportType,
        macAddress: macAddress || null,
        phoneNumber: phoneNumber || null,
        serialNumber: serialNumber || null,
        auth: auth || null
      };

      if (fromDate && toDate) {
        requestBody.fromDate = formatDate(fromDate);
        requestBody.toDate = formatDate(toDate);
      }

      const response = await fetch(`${BASE_URL}/Report/GetPOSTransactionLogs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setReportData(data);
      //console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //handle search
  const handleSearchClick = () => {
    if (selectedOption === null) {
      setShowPopup(true);
    } else {
      fetchData(selectedOption.value);
    }
  };

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const transactionsLogs = reportData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(reportData.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const onHide = () => {
    setShowPopup(false);
  };

  //handle excel sheet to export
  const columnsToExport = reportData.map(item => ({
    [t("referenceNumber")]: item.Reference,
    [t("userName")]: item.UserName,
    [t("phoneNumber")]: item.PhoneNumber,
    [t("transactionAmount")]: item.Amount,
    [t("SerialNumber")]: item.SerialNumber,
    [t("MacAddress")]: item.MacAddress,
    [t("AUTH")]: item.AUTH,
    [t("PAN")]: item.PAN,
    [t("TID")]: item.TID,
    [t("transferDate")]: format(new Date(item.TransDate), "dd/MM/yy"),
    [t("time")]: format(new Date(item.TransDate), "h:mm a"),
    [t("IsSuccess")]: item.IsSuccess ? "ناجحة" : "فاشلة"
  }));
  const selectedLabel = selectedOption ? options.find(option => option.value === selectedOption.value).label : "";

  //handle cancel click
  const handleCancelClick = () => {
    setMacAddress("");
    setAuth("");
    setPhoneNumber("");
    setFromDate("");
    setToDate("");
    setSelectedOption("");
    setSerialNumber("");
  };

  return (
    <div className="report-container">
      <h3 className="text-main m-3 report-head">{t("posTransactionLogs")}</h3>
      <div className="card mb-4 custom-card  m-3 d-flex flex-row ">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between p-4">
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="h6">{t("reportType")}: </span>
                <Dropdown
                  value={selectedOption}
                  options={options}
                  onChange={handleOptionChange}
                  placeholder={selectedLabel || t("choose")}
                  className="tergetReport-dropdown w-100"
                />
              </div>
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="h6">{t("from")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={fromDate}
                    onChange={e => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="ml-2 h6">{t("to")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={toDate}
                    onChange={e => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="w-100 p-4">
                <div className="d-flex flex-column">
                  <span className="h6">{t("MacAddress")}: </span>
                  <InputText
                    className="tergetReport-dropdown p-2 w-100"
                    value={macAddress}
                    onChange={e => setMacAddress(e.target.value)}
                    placeholder={t("search")}
                  />
                </div>
              </div>

              <div className="w-100 p-4">
                <div className="d-flex flex-column">
                  <span className="h6">{t("phoneNumber")}: </span>
                  <InputText
                    className="tergetReport-dropdown p-2 w-100"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    placeholder={t("search")}
                  />
                </div>
              </div>

              <div className="w-100 p-4">
                <div className="d-flex flex-column">
                  <span className="ml-2 h6">{t("SerialNumber")}: </span>
                  <InputText
                    className="tergetReport-dropdown p-2 w-100"
                    value={serialNumber}
                    onChange={e => setSerialNumber(e.target.value)}
                    placeholder={t("search")}
                  />
                </div>
              </div>
              <div className="w-100 p-4">
                <div className="d-flex flex-column">
                  <span className="ml-2 h6">{t("AUTH")}: </span>
                  <InputText
                    className="tergetReport-dropdown p-2 w-100"
                    value={auth}
                    onChange={e => setAuth(e.target.value)}
                    placeholder={t("search")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 m-3">
              <Button
                onClick={handleSearchClick}
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn"
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff", width: "117px", height: "50px" }}
                className="rounded-2 "
              />
            </div>
          </div>
          <div className="w-100 p-4" />
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive p-2">
              <Table striped hover>
                <thead className="custom-thead">
                  <tr>
                    <th className="text-light ">{t("referenceNumber")}</th>
                    <th className="text-light ">{t("userName")}</th>
                    <th className="text-light ">{t("phoneNumber")}</th>
                    <th className="text-light ">{t("transactionAmount")}</th>
                    <th className="text-light ">{t("SerialNumber")}</th>
                    <th className="text-light ">{t("MacAddress")}</th>
                    <th className="text-light ">{t("AUTH")}</th>
                    <th className="text-light  ">{t("PAN")}</th>
                    <th className="text-light ">{t("TID")}</th>
                    <th className="text-light ">{t("transferDate")}</th>
                    <th className="text-light ">{t("time")}</th>
                    <th className="text-light ml-5 ">{t("IsSuccess")}</th>
                  </tr>
                </thead>
                <tbody className="text-center transactionlogs-table">
                  {transactionsLogs?.map((item, index) => (
                    <tr key={index}>
                      <td width="100px " className="ml-4">
                        {item?.Reference}
                      </td>
                      <td width="100px " className="ml-4">
                        {item?.UserName}
                      </td>
                      <td width="100px " className="ml-4">
                        {item?.PhoneNumber}
                      </td>
                      <td width="100px " className="ml-4">
                        {item?.Amount}
                      </td>
                      <td width="100px " className="ml-4">
                        {item?.SerialNumber}
                      </td>
                      <td width="100px " className="ml-4">
                        {item?.MacAddress}
                      </td>
                      <td width="100px " className="ml-4">
                        {item?.AUTH}
                      </td>
                      <td width="100px " className="ml-4">
                        {item?.PAN}
                      </td>
                      <td width="100px " className="ml-4">
                        {item?.TID}
                      </td>
                      <td width="100px " className="text-center">
                        {format(new Date(item.TransDate), "dd/MM/yy")}
                      </td>
                      <td dir="ltr" width="100px" className="text-center">
                        {format(new Date(item.TransDate), "h:mm a")}
                      </td>

                      <td width="100px">{item?.IsSuccess ? "ناجحة" : "فاشلة"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="text-center">
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  indexOfLastRow={indexOfLastRow}
                  reportData={reportData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <PopupMessage visible={showPopup} onHide={onHide} message={t("selectOptionMessagePopup")} closable={true} onBlur={true} />
    </div>
  );
}
