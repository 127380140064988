import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Table } from "react-bootstrap";
import ExcelExporter from "../../ExcelExporter/ExcelExporter";
import { BASE_URL } from "../../context/Enviroment";
import { Dialog } from "primereact/dialog";
import CustomPagination from "../../Components/Paginator/Paginator";
import { InputSwitch } from "primereact/inputswitch";
import { ToggleButton } from "primereact/togglebutton";
import "./PricesSettings.scss";
import PopupMessage from "../../Components/AlertPopup/Popup";
import { Player } from "@lottiefiles/react-lottie-player";
import { Toast } from "primereact/toast";
import * as XLSX from "xlsx";
import { getAllChildCustomersApi, getAllPricesApi, getBrandsApi } from "../auth/Services/ContentAPIService";

export default function PricesSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCustomersChild, setSelectedCustomersChild] = useState(null);
  const toast = useRef(null);
  const userRole = localStorage.getItem("role");
  const [showButton, setShowButton] = useState(userRole !== "Agent");

  //get all brands
  const [brands, setBrands] = useState();

  const getBrands = async () => {
    setIsLoading(true);

    try {
      const brandData = await getBrandsApi();
      setBrands(brandData);
    } catch (error) {
      console.error("An error occurred while fetching brands:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //get all child customers
  const [customersChild, setCustomersChild] = useState([]);

  const getAllCustomers = async () => {
    try {
      const childCustomersData = await getAllChildCustomersApi();
      setCustomersChild(childCustomersData);
    } catch (error) {
      console.error("An error occurred while fetching child customers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // all prices request

  const [pricesData, setPricesData] = useState([]);
  const getAllPrices = async selectedChildId => {
    setIsLoading(true);
    try {
      const prices = await getAllPricesApi(selectedBrand, selectedChildId);
      setPricesData(prices);
    } catch (error) {
      console.error("An error occurred while fetching prices:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchClick = () => {
    getAllPrices(selectedCustomersChild);
  };

  useEffect(() => {
    getBrands();
    getAllCustomers();
  }, []);

  const handleBrandChange = e => {
    setSelectedBrand(e.value);
  };
  const { t, i18n } = useTranslation();

  //handle excel sheet to export
  const columnsToExport = pricesData?.map(item => ({
    [t("productNumber")]: item.ProductCode,
    [t("ProductName")]: item.ProductName,
    [t("serviceName")]: item.ServiceName,
    [t("cost")]: item.ParentCost,
    [t("ProductMaxSellPrice")]: item.ProductMaxSellPrice,
    [t("sellPrice")]: item.SellPrice,
    [t("endUserPrice")]: item.EndUserPrice
  }));

  //edit price

  const [productData, setProductData] = useState(/* Your data here */);
  const [editPriceValue, setEditPriceValue] = useState("");
  const [editPriceId, setEditPriceId] = useState(null);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [brandIds, setBrandIds] = useState([]);

  const [currentValue, setCurrentValue] = useState("");

  const [updatedPrices, setUpdatedPrices] = useState([]);

  const handleEditPrice = (productId, brandIds, sellPrice) => {
    setEditPriceId(productId);
    setEditPriceValue(sellPrice);
    setBrandIds(brandIds);
    setCurrentValue(sellPrice);
  };

  const sendUpdatedPricesToBackend = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const requestData = {
        customerId: selectedCustomersChild,
        productPrices: updatedPrices
      };

      const response = await fetch(`${BASE_URL}/Prices/AddUpdateCopyPrices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response?.status}`);
      }
      toast.current.show({ severity: "success", detail: t("editResponseMessage"), life: 3000 });

      getAllPrices(selectedCustomersChild);

      // Clear the accumulated prices
      setUpdatedPrices([]);
    } catch (error) {
      console.error("Error updating prices:", error);
      toast.current.show({ severity: "error", detail: "Failed", life: 3000 });
    }
  };
  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentReportData = pricesData?.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(pricesData?.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //active button
  const [active, setActive] = useState(true);

  const handleToggle = () => {
    setActive(!active);
  };

  //handle Popup
  const [showPopup, setShowPopup] = useState(false);
  const onHide = () => {
    setShowPopup(false);
  };

  const [showDialog, setShowDialog] = useState(false);
  const showAlertDialog = () => {
    setShowDialog(true);
  };
  const hideAlertDialog = () => {
    setShowDialog(false);
  };

  //handle upload file
  const [selectedFile, setSelectedFile] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [displayData, setDisplayData] = useState(false); // Added state for displaying data

  const handleFileSelection = e => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onload = e => {
      const fileData = e.target.result;
      const workbook = XLSX.read(fileData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      jsonData.shift();

      setExcelData(jsonData);
    };

    reader.readAsBinaryString(file);
  };

  const handleDisplayData = () => {
    setDisplayData(true);
    setPricesData(excelData);
  };

  //handle cancel click
  const handleCancelClick = () => {
    setSelectedBrand("");
    setSelectedCustomersChild("");
  };
  return (
    <div className="report-container">
      <h3 className="text-main m-3 px-2 report-head">{t("priceSettings")}</h3>

      <Toast ref={toast} position="top-center" />

      <div className="card  mb-4 custom-card m-3 p-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="ml-2">
              <div className="d-flex gap-5 align-items-center p-4">
                <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                  <span className="m-2 h6">{t("selectBrand")} :</span>
                  <Dropdown
                    className="tergetReport-dropdown w-100"
                    value={selectedBrand}
                    options={brands}
                    onChange={handleBrandChange}
                    optionLabel="Name"
                    placeholder={t("choose")}
                  />
                </div>

                <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                  <span className="m-2 h6">{t("clients")} :</span>
                  <Dropdown
                    className="tergetReport-dropdown w-100"
                    value={selectedCustomersChild}
                    options={customersChild?.map(customer => ({
                      label: customer?.User?.UserFullName,
                      value: customer?.Id
                    }))}
                    onChange={e => {
                      const selectedChildId = e.value;
                      setSelectedCustomersChild(selectedChildId);
                      // getAllPrices(selectedChildId);
                    }}
                    placeholder={t("choose")}
                    filter
                    filterBy="label"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns mb-4 ">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 ">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2"
                onClick={handleSearchClick}
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff" }}
                className="rounded-2 cancel-btn w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />

          {showButton && (
            <Button
              label={t("updatePrices")}
              style={{ border: "none", backgroundColor: "#007bff", color: "#fff", width: "190px", height: "50px" }}
              className="rounded-2 btn main-bg"
              onClick={sendUpdatedPricesToBackend}
            />
          )}
        </div>

        <Table striped hover>
          <thead className="custom-thead">
            <tr className="text-center">
              <th className="text-light">{t("productNumber")}</th>
              <th className="text-light">{t("ProductName")}</th>
              <th className="text-light">{t("serviceName")}</th>
              <th className="text-light">{t("cost")}</th>
              <th className="text-light">{t("maxPrice")}</th>
              <th className="text-light">{t("sellPrice")}</th>
              <th className="text-light">{t("endUserPrice")}</th>
            </tr>
          </thead>
          <tbody>
            {currentReportData.map((rowData, index) => (
              <tr key={index}>
                <td width="80px" className="text-center">
                  {rowData.ProductCode}
                </td>
                <td width="80px" className="text-center">
                  {rowData.ProductName}
                </td>
                <td width="80px" className="text-center">
                  {rowData.ServiceName}
                </td>
                <td width="80px" className="text-center">
                  {rowData.ParentCost}
                </td>
                <td width="80px" className="text-center">
                  {rowData.ProductMaxSellPrice}
                </td>
                <td width="80px" className="text-center">
                  <input
                    className="form-control"
                    onChange={e => {
                      const newValue = e.target.value;
                      if (rowData.SellPrice && newValue > rowData.EndUserPrice) {
                        showAlertDialog();
                      } else {
                        const updatedPrice = {
                          productId: rowData.ProductId,
                          price: newValue,
                          IsActive: true,
                          brandIds: rowData.brandIds
                        };
                        const updatedPricesCopy = [...updatedPrices];
                        const index = updatedPricesCopy.findIndex(item => item.productId === rowData.ProductId);
                        if (index !== -1) {
                          updatedPricesCopy[index] = updatedPrice;
                        } else {
                          updatedPricesCopy.push(updatedPrice);
                        }
                        setUpdatedPrices(updatedPricesCopy);
                        setEditPriceValue(newValue);
                      }
                    }}
                    value={rowData.ProductId === editPriceId ? editPriceValue : rowData.ProductPrice}
                    defaultValue={rowData.SellPrice}
                    readOnly={rowData.ProductId !== editPriceId || userRole === "Agent"}
                    onClick={() => handleEditPrice(rowData.ProductId, rowData.brandIds, rowData.SellPrice)}
                  />
                </td>
                <td width="80px" className="text-center">
                  {rowData.EndUserPrice}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          indexOfLastRow={indexOfLastRow}
          reportData={pricesData}
        />
        {/* )} */}

        <Dialog
          visible={showDialog}
          onHide={hideAlertDialog}
          onBlur={hideAlertDialog}
          style={{ width: "600px", height: "500px", direction: "rtl" }}
        >
          <h4 className="mb-4 text-center">{t("maxPriceAlert")}</h4>
          <Player
            autoplay
            loop
            src="https://lottie.host/1f8afeb1-1b85-42df-827f-4854670150ac/Ci9NllXWLn.json"
            style={{ height: "300px", width: "300px" }}
          />
        </Dialog>
      </div>
    </div>
  );
}
