import React, { createContext, useState, useEffect } from "react";
import Swal from "sweetalert2";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    // Load cart items from local storage on component mount
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(storedCartItems);
  }, []);

  useEffect(() => {
    // Update local storage when cartItems change
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    
    // Calculate total quantity and update cart count
    const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
    setCartCount(totalQuantity);
  }, [cartItems]);

  const addToCart = (productId) => {
    const existingProduct = cartItems.find((item) => item.productId === productId);

    if (existingProduct) {
      Swal.fire({
        position: "top-start",
        icon: "success",
        title: "Already Selected",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    const updatedCartCount = (JSON.parse(localStorage.getItem("cartProducts")) || []).length;
    setCartCount(updatedCartCount);
    setCartItems((prevCartItems) => [...prevCartItems, { productId, quantity: 1 }]);
  };

  const removeFromCart = (productId) => {
    const updatedCart = cartItems.filter((item) => item.productId !== productId);
    setCartItems(updatedCart);
  };

  return (
    <CartContext.Provider value={{ cartItems, cartCount, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};
