import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";
import { useTranslation } from "react-i18next";
import { Table, Pagination } from "react-bootstrap";
import Loading from "../../../Components/Loading/Loading";
import { BASE_URL } from "../../../context/Enviroment";
import PopupMessage from "../../../Components/AlertPopup/Popup";
import "./GetTargetMobile.scss";
import CustomPagination from "../../../Components/Paginator/Paginator";
import { fetchProductsForBrandApi, getAllChildCustomersApi, getBrandsApi } from "../../auth/Services/ContentAPIService";

function GetTargetMobile() {
  const { t, i18n } = useTranslation();
  const [reportData, setReportData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [customersParents, setCustomersParents] = useState([]);
  const [customersChild, setCustomersChild] = useState([]);
  const [pricesData, setPricesData] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [currentPricesPage, setCurrentPricesPage] = useState(1);
  const [totalPricesPages, setTotalPricesPages] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Set the initial page size to 10 rows

  //Api request
  const fetchData = async () => {
    setIsLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      const reportType = selectedOption === "اجمالي" ? 1 : 2;

      const requestBody = {
        reportType: reportType
      };

      if (fromDate && toDate) {
        requestBody.fromDate = formatDate(fromDate);
        requestBody.toDate = formatDate(toDate);
      }
      // requestBody.brandId = selectedBrand || null;
      // requestBody.parentId = selectedCustomersParents || null;
      // requestBody.childId = selectedCustomersChild || null;
      // requestBody.productId = selectedProduct || null;

      const response = await fetch(`${BASE_URL}/Report/GetTargetMobile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setReportData(data.Data.Profits);
      // //console.log(data.Data.Profits);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  //get all brands
  const [brands, setBrands] = useState();

  const getBrands = async () => {
    setIsLoading(true);

    try {
      const brandData = await getBrandsApi();
      setBrands(brandData);
    } catch (error) {
      console.error("An error occurred while fetching brands:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // get all products
  const [products, setProducts] = useState();
  const fetchProductsForBrand = async brandId => {
    setIsLoading(true);
    const requestBody = JSON.stringify({
      brandId: String(brandId)
    });
    try {
      const productOptions = await fetchProductsForBrandApi(brandId);
      setProducts(productOptions);
    } catch (error) {
      console.error("An error occurred while fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //get all child customers
  const getAllChildCustomers = async () => {
    try {
      const childCustomersData = await getAllChildCustomersApi();
      setCustomersChild(childCustomersData);
    } catch (error) {
      console.error("An error occurred while fetching child customers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBrands();
    getAllChildCustomers();
  }, []);

  //handle pagination
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleOptionChange = e => {
    setSelectedOption(e.value);
  };

  const options = [{ label: "اجمالي", value: "اجمالي" }, { label: "تفصيلي", value: "تفصيلي" }];
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCustomersParents, setSelectedCustomersParents] = useState(null);
  const [selectedCustomersChild, setSelectedCustomersChild] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleBrandChange = e => {
    const selectedBrandId = e.value?.Id;
    setSelectedBrand(selectedBrandId);

    fetchProductsForBrand(selectedBrandId);
  };

  const [showProductNameColumn, setShowProductNameColumn] = useState(true);

  const handleSearchClick = () => {
    if (selectedOption === null) {
      setShowPopup(true);
    } else {
      setShowProductNameColumn(!showProductNameColumn);
      fetchData();
    }
  };
  const onHide = () => {
    setShowPopup(false);
  };

  //handle excel sheet to export
  const columnsToExport = reportData.map(item => ({
    [t("userName")]: item.UserFullName,
    [t("ProductName")]: item.ProductName,
    [t("phone")]: item.PhoneNumber,
    [t("totalSales")]: item.Profite,
    [t("quantity")]: item.Quantity
  }));

  //handle search in all data
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };
  const filteredData = reportData.filter(rowData => {
    return (
      rowData.UserFullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (showProductNameColumn && rowData.ProductName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      rowData.PhoneNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Pagination
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentReportData = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  //handle cancel click
  const handleCancelClick = () => {
    setFromDate("");
    setToDate("");
    setBrands("");
    setProducts("");
    setCustomersParents("");

    setSelectedBrand("");
    selectedProduct("");
    setCustomersChild("");
    setSelectedOption("");
  };

  return (
    <div className="report-container container">
      <h3 className="text-main m-3 report-head">{t("reports")}</h3>
      <div className="card container mb-4 custom-card m-3 p-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="h6">{t("reportType")}: </span>
                <Dropdown
                  className="tergetReport-dropdown w-100 col-sm-12"
                  value={selectedOption}
                  options={options}
                  onChange={handleOptionChange}
                  placeholder={t("choose")}
                />
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="h6">{t("from")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={fromDate}
                    onChange={e => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="ml-2 h6">{t("to")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={toDate}
                    onChange={e => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>
            </div>
            <div className=" d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-2 h6">{t("selectBrand")} :</span>
                <Dropdown
                  className="tergetReport-dropdown w-100"
                  value={selectedBrand}
                  options={brands}
                  onChange={handleBrandChange}
                  optionLabel="Name"
                  placeholder={t("choose")}
                />
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-2 h6">{t("selectProduct")} : </span>
                <Dropdown
                  className="tergetReport-dropdown w-100"
                  value={selectedProduct}
                  options={products}
                  onChange={e => setSelectedProduct(e.value)}
                  optionLabel="label"
                  placeholder={t("choose")}
                />
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-2 h6">{t("clients")} :</span>
                <Dropdown
                  className="tergetReport-dropdown w-100"
                  value={selectedCustomersChild}
                  options={customersChild?.map(customer => ({
                    label: customer?.User?.UserFullName,
                    value: customer?.Id
                  }))}
                  onChange={e => {
                    const selectedChildId = e.value;
                    setSelectedCustomersChild(selectedChildId);
                    fetchData(selectedChildId);
                  }}
                  placeholder={t("choose")}
                  filter
                  filterBy="label"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2"
                onClick={handleSearchClick}
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff" }}
                className="rounded-2 cancel-btn w-100"
              />
            </div>
          </div>
        </div>
      </div>
      ;
      <PopupMessage visible={showPopup} onHide={onHide} message={t("selectOptionMessagePopup")} closable={true} onBlur={true} />
      <div className="card container m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
          <input
            type="text"
            placeholder={t("search")}
            className="form-control w-25"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <Table className="table table-striped table-hover">
                <thead className="custom-thead">
                  <tr>
                    <th className="text-light"> {t("userName")} </th>
                    {showProductNameColumn && <th className="text-light"> {t("ProductName")} </th>}
                    <th className="text-light"> {t("phone")} </th>
                    <th className="text-light"> {t("quantity")} </th>
                    <th className="text-light"> {t("clientProfit")} </th>
                    <th className="text-light"> {t("TotalUserSales")} </th>
                    <th className="text-light"> {t("TotalEndUserPrice")} </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {currentReportData.map((rowData, index) => (
                    <tr key={index}>
                      <td> {rowData.UserFullName} </td>
                      {showProductNameColumn && <td> {rowData.ProductName} </td>}
                      <td> {rowData.PhoneNumber} </td>
                      <td> {rowData.Quantity} </td>
                      <td> {rowData.Profite} </td>
                      <td> {rowData.TotalUserSales} </td>
                      <td> {rowData.TotalEndUserPrice} </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfLastRow={indexOfLastRow}
                reportData={reportData}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GetTargetMobile;
