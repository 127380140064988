import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../context/Enviroment";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import Loading from "../../Components/Loading/Loading";
import ExcelExporter from "../../ExcelExporter/ExcelExporter";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Swal from "sweetalert2";
import img from "../../assets/Images/logo/logo.png";
import "./Transactions.scss";
import CustomPagination from "../../Components/Paginator/Paginator";
import { getTransactionsApi } from "../auth/Services/ContentAPIService";

export default function Transactions() {
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [visible, setVisible] = useState(false);

  function formatDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
    return "";
  }

  //get transactions request
  const getTransactions = async () => {
    setIsLoading(true);

    try {
      const transactionData = await getTransactionsApi(fromDate, toDate, formatDate);
      setTransactions(transactionData);
    } catch (error) {
      console.error("An error occurred while fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const showTransactionDetails = transaction => {
    setSelectedTransaction(transaction);
    setVisible(true);
  };

  function handlePrint() {
    window.print();
  }

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const customersData = transactions.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(transactions.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle cancel click
  const handleCancelClick = () => {
    setFromDate("");
    setToDate("");
  };
  return (
    <div className="report-container">
      <h3 className="text-main m-3 report-head">{t("customersTransactions")}</h3>

      <div className="card  mb-4 custom-card m-3 p-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-row gap-3 mb-3 w-100 align-items-center">
                <span className="h6">{t("from")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={fromDate}
                    onChange={e => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>

              <div className="custom-dropdown d-flex flex-row gap-3 mb-3 w-100 align-items-center">
                <span className="ml-2 h6">{t("to")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={toDate}
                    onChange={e => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 mt-0">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2"
                onClick={getTransactions}
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff" }}
                className="rounded-2 cancel-btn w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter fileName="report_data" />
        </div>
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <Table striped hover>
                <thead className="custom-thead">
                  <tr className="text-center">
                    <th className="text-light">{t("from")}</th>
                    <th className="text-light">{t("to")}</th>
                    <th className="text-light">{t("balanceBeforeTransfer")}</th>
                    <th className="text-light">{t("transferBalance")}</th>
                    <th className="text-light">{t("balanceAfterTransfer")}</th>
                    <th className="text-light">{t("transferDate")}</th>
                    <th className="text-light">{t("time")}</th>
                  </tr>
                </thead>
                <tbody>
                  {customersData.map((transaction, index) => (
                    <tr key={index} onClick={() => showTransactionDetails(transaction)}>
                      <td width="80px" className="text-center">
                        {transaction.FromUserName}
                      </td>
                      <td width="80px" className="text-center">
                        {transaction.ToUserName}
                      </td>
                      <td width="80px" className="text-center">
                        {(transaction.Balance - transaction.TransferedBalance).toFixed(2)}
                      </td>

                      <td width="80px" className="text-center">
                        {transaction.TransferedBalance}
                      </td>
                      <td width="80px" className="text-center">
                        {transaction.Balance}
                      </td>

                      <td width="80px" className="text-center">
                        {format(new Date(transaction.TransferDate), "dd/MM/yyyy")}
                      </td>
                      <td dir="ltr" width="80px" className="text-center">
                        {format(new Date(transaction.TransferDate), "h:mm a")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfLastRow={indexOfLastRow}
                reportData={transactions}
              />
            </div>
          )}
        </div>
      </div>
      <Dialog
        visible={visible}
        style={{ width: "800px", height: "900px" }}
        onHide={() => setVisible(false)}
        className="dialog-for-print"
        draggable={false}
      >
        {selectedTransaction && (
          <div dir="rtl" className="container  w-100 h-100 transaction-container text-center">
            <div className="d-flex justify-content-between mb-5">
              <button
                className="print-btn rounded-2 btn"
                style={{ border: "none", backgroundColor: "#e58241", color: "#fff" }}
                onClick={handlePrint}
                height="40px"
              >
                طباعة
              </button>
              <img src={img} width="150" alt="Logo" className="logo-left" />
            </div>

            <h2 className="underline-text d-flex justify-content-center mb-5">اشعار ايداع في حساب</h2>
            <div className="row mt-4 w-100 d-flex align-items-center centered-content">
              <div className="col-6">
                <span className="font-weight-bold">التاريخ : </span>
                <span dir="ltr">{format(new Date(selectedTransaction.TransferDate), "dd/MM/yy")}</span>
              </div>
              <div className="col-6">
                <span className="font-weight-bold"> الوقت : </span>
                <span dir="ltr"> {format(new Date(selectedTransaction.TransferDate), "h:mm a")}</span>
              </div>
            </div>
            <div className="row w-100 d-flex align-items-center mt-3">
              <div className="col-6 ">
                <span className="font-weight-bold pl-5 ">رقم القيد :</span>
                <span className="pr-5"> </span>
              </div>
            </div>
            <div className="row mt-4 w-100 d-flex align-items-center">
              <div className="col-6">
                <span className="font-weight-bold">من :</span>
                <span>{selectedTransaction.FromUserName}</span>
              </div>
              <div className="col-6">
                <span className="font-weight-bold">الى :</span>
                <span>{selectedTransaction.ToUserName}</span>
              </div>
            </div>
            <div className="row mt-4 w-100 d-flex align-items-center">
              <div className="col-6">
                <span className="font-weight-bold">رقم حساب العميل</span>
                <span className="company-number"> </span>
              </div>
            </div>
            <div className="row mt-4 w-100 d-flex align-items-center">
              <div className="col-6">
                <span>السادة شركة / {selectedTransaction.ToUserName}</span>
              </div>
            </div>
            <div className="mt-4">
              <span>نعلمكم انه قد قيد لحسابكم المذكور أعلاه المبلغ أدناه :</span>
            </div>
            <div className="d-flex justify-content-center">
              <div className="mt-4 w-50">
                <table className="table table-bordered">
                  <thead className="table-head">
                    <tr>
                      <th>العملة</th>
                      <th>المبلغ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ريال سعودى</td>
                      <td>{selectedTransaction.TransferedBalance}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-center">
              <span>المبلغ : </span>
            </div>
            <div className="row w-100 mt-4 align-items-center">
              <div className="col-6 mb-3">
                <span className="font-weight-bold">ملاحظة : تعبئة رصيد - نظام مدى </span>
                <span dir="ltr" />
              </div>
              <div className="col-6 mb-3">
                <span className="font-weight-bold underline-text">الإدارة المالية</span>
                <span dir="ltr" />
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}
