import React, { useEffect, useRef, useState } from "react";
import "./Customers.scss";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { BASE_URL } from "../../context/Enviroment";
import { Table } from "react-bootstrap";
import ExcelExporter from "../../ExcelExporter/ExcelExporter";
import Loading from "../../Components/Loading/Loading";
import { Dialog } from "primereact/dialog";
import NewWindow from "react-new-window";
import logo from "../../assets/Images/logo/logo.png";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Player } from "@lottiefiles/react-lottie-player";
import CustomPagination from "../../Components/Paginator/Paginator";
import toast from "react-hot-toast";
import { addBalanceApi, getAllChildCustomersApi } from "../auth/Services/ContentAPIService";
import { format } from "date-fns";

export default function Customers() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomerForBalance, setSelectedCustomerForBalance] = useState(null);
  const userRole = localStorage.getItem("role");
  const [showButton, setShowButton] = useState(userRole !== "Agent");

  // get customers
  const [customersChild, setCustomersChild] = useState([]);

  const getAllChildCustomers = async selectedChildId => {
    setIsLoading(true);
    try {
      const childCustomersData = await getAllChildCustomersApi();
      setCustomersChild(childCustomersData);
    } catch (error) {
      console.error("An error occurred while fetching child customers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllChildCustomers();
  }, []);

  const transactionFrom = localStorage.getItem("userName");

  //add balance function
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const [amountValue, setAmountValue] = useState(1);

  const addBalance = async () => {
    setIsLoading(true);

    try {
      let data;

      const userRole = localStorage.getItem("role");
      const adminRole = localStorage.getItem("adminRole");

      if (adminRole === "SuperAdmin") {
        data = await addBalanceApiForAdmin(amountValue, selectedCustomerId);
      } else {
        data = await addBalanceApi(amountValue, selectedCustomerId);
      }

      getAllChildCustomers();
      setDialogVisible(true);

      const selectedCustomer = customersChild.find(customer => customer.Id === selectedCustomerId);

      if (selectedCustomer) {
        setSelectedCustomerForBalance(selectedCustomer);
        const {
          User: { UserFullName, UserName, Balance }
        } = selectedCustomer;
        const oldBalance = Balance;
        const newBalance = oldBalance + parseFloat(amountValue);

        setDialogData({
          date: format(new Date(), "dd/MM/yy"),
          time: format(new Date(), "h:mm a"),
          recordNumber: "123",
          fromUser: transactionFrom,
          toUser: UserFullName,
          accountNumber: UserName,
          transferredBalance: parseFloat(amountValue).toFixed(2),
          note: "تعبئة رصيد - نظام مدى",
          management: "الإدارة المالية",
          oldBalance: oldBalance,
          newBalance: newBalance
        });
      }
    } catch (error) {
      console.error("Error adding balance:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const addBalanceApiForAdmin = async (amountValue, selectedCustomerId) => {};

  //handle excel exporter
  const columnsToExport = customersChild.map(item => ({
    [t("productNumber")]: item.User.UserFullName,
    [t("phone")]: item.User.UserName,
    [t("userType")]: item.UserType,
    [t("balance")]: item.User.Balance
  }));

  //handle Popup
  const [showPopup, setShowPopup] = useState(false);
  const onHide = () => {
    setShowPopup(false);
  };

  const [showDialog, setShowDialog] = useState(false);
  const showAlertDialog = customerId => {
    setSelectedCustomerId(customerId);
    setShowDialog(true);
  };
  const hideAlertDialog = () => {
    setShowDialog(false);
  };

  //handle confirmation popup
  const [visible, setVisible] = useState(false);

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const customersData = customersChild.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(customersChild.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle amount value validation
  const handleInputChange = e => {
    const inputValue = parseInt(e.target.value, 10);
    if (inputValue >= 1) {
      setAmountValue(inputValue.toString());
    }
  };

  const handleBlur = () => {
    const inputValue = parseInt(amountValue, 10);
    if (isNaN(inputValue) || inputValue < 1) {
      toast.error("Value less than 1 is not allowed.");
      setAmountValue(1);
    }
  };

  const isButtonDisabled = isNaN(parseInt(amountValue, 10)) || parseInt(amountValue, 10) < 1;
  const popupBody = () => {
    return (
      <div>
        <h4 className="mb-4 text-center"> {t("confirmationMessage")}</h4>
        <Player
          autoplay
          loop
          src="https://lottie.host/cfc645e6-55e2-426d-af3e-524a283ae70f/HyEhlfbhVw.json"
          style={{ height: "150px", width: "150px" }}
        />
      </div>
    );
  };

  //handlw dialoge transaction
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const handleCloseDialog = () => {
    setDialogVisible(false);
    setDialogData({});
  };
  const openDialog = () => {
    setDialogVisible(true);
  };

  function handlePrint() {
    window.print();
  }

  return (
    <div className="customers-container">
      <h3 className="text-main m-3 report-head">{t("customers")}</h3>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <Table striped hover>
                <thead className="custom-thead">
                  <tr className="text-center">
                    <th className="text-light">{t("userName")}</th>
                    <th className="text-light">{t("phone")}</th>
                    <th className="text-light">{t("userType")}</th>
                    <th className="text-light">{t("balance")}</th>
                    {showButton && <th className="text-light">{t("addBalance")}</th>}
                  </tr>
                </thead>
                <tbody>
                  {customersData.map((rowData, index) => (
                    <tr key={index} className={rowData.Id === selectedCustomerForBalance?.Id ? "selected-row" : ""}>
                      <td width="80px" className="text-center">
                        {rowData.User.UserFullName}{" "}
                      </td>

                      <td width="80px" className="text-center">
                        {rowData.User.UserName}{" "}
                      </td>
                      <td width="80px" className="text-center">
                        {rowData.UserType === 3
                          ? "POS"
                          : rowData.UserType === 1
                          ? "Agent"
                          : rowData.UserType === 2
                          ? "Distributor"
                          : "Unknown"}
                      </td>
                      <td width="80px" className="text-center">
                        {rowData.User.Balance}
                      </td>
                      {showButton && (
                        <td width="80px" className="text-center">
                          <Button
                            label={t("addBalance")}
                            className="rounded-2 "
                            onClick={() => showAlertDialog(rowData.Id)}
                            severity="warning"
                            outlined
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfLastRow={indexOfLastRow}
                reportData={customersChild}
              />
            </div>
          )}
        </div>
        <Dialog
          visible={showDialog}
          onHide={() => setShowDialog(false)}
          style={{ width: "600px", height: "300px", direction: "rtl" }}
        >
          <h4 className="mb-4 text-center">{t("addBalance")}</h4>
          <input
            type="number"
            className="form-control mb-3"
            id="exampleInputEmail1"
            value={amountValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            placeholder="Add Amount"
            style={{ height: "50px" }}
          />

          <Button
            label={t("addBalance")}
            style={{ border: "none", backgroundColor: "#007bff", color: "#fff", width: "110px", height: "40px" }}
            className={`rounded-2 btn main-bg ${isButtonDisabled ? "disabled" : ""}`}
            onClick={() => {
              if (!isButtonDisabled) {
                setVisible(true);
              }
            }}
          />
        </Dialog>
        <div className="confirm-dialog-container">
          <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message={popupBody}
            accept={addBalance}
            reject={() => setVisible(false)}
            style={{ height: "450px", width: "500px", justifyContent: "center" }}
          />
        </div>

        <Dialog
          visible={dialogVisible}
          onHide={handleCloseDialog}
          style={{ width: "800px", height: "900px" }}
          className="dialog-for-print"
          draggable={false}
        >
          {selectedCustomerForBalance && (
            <div dir="rtl" className="container  w-100 h-100 transaction-container text-center">
              <div className="d-flex justify-content-between mb-5">
                <button
                  className="print-btn rounded-2 btn"
                  style={{ border: "none", backgroundColor: "#e58241", color: "#fff" }}
                  onClick={handlePrint}
                  height="40px"
                >
                  طباعة
                </button>
                <img src={logo} width="150" alt="Logo" className="logo-left" />
              </div>

              <h2 className="underline-text d-flex justify-content-center mb-5">اشعار ايداع في حساب</h2>
              <div className="row mt-4 w-100 d-flex align-items-center justify-content-between">
                <div className="w-25 text-end ">
                  <span className="font-weight-bold">التاريخ : </span>
                  <span dir="ltr">{dialogData.date}</span>
                </div>
                <div className="w-25 text-end mx-5">
                  <span className="font-weight-bold"> الوقت : </span>
                  <span dir="ltr">{dialogData.time}</span>
                </div>
              </div>

              <div className="row w-100 d-flex align-items-center mt-3">
                <div className="w-100 text-end ">
                  <span className="font-weight-bold pl-5 ">رقم القيد : </span>
                  <span className="pr-5" />
                </div>
              </div>

              <div className="row mt-4 w-100 d-flex align-items-center justify-content-around">
                <div className="w-50 text-end ">
                  <span className="font-weight-bold">من : </span>
                  <span>{dialogData.fromUser}</span>
                </div>
                <div className="w-50 text-start ">
                  <span className="font-weight-bold">الى : </span>
                  <span>{dialogData.toUser}</span>
                </div>
              </div>

              <div className="row mt-4 w-100 d-flex align-items-center">
                <div className="w-100 text-end ">
                  <span className="font-weight-bold">رقم حساب العميل : </span>
                  <span className="company-number">{dialogData.accountNumber}</span>
                </div>
              </div>

              <div className="row mt-4 w-100 d-flex align-items-center">
                <div className="w-100 text-end ">
                  <span>السادة شركة / {dialogData.toUser}</span>
                </div>
              </div>
              <div className="mt-4">
                <span>نعلمكم انه قد قيد لحسابكم المذكور أعلاه المبلغ أدناه :</span>
              </div>
              <div className="d-flex justify-content-center">
                <div className="mt-4 w-50">
                  <table className="table table-bordered">
                    <thead className="table-head">
                      <tr>
                        <th>المبلغ</th>
                        <th>العملة</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{dialogData.transferredBalance}</td>
                        <td>ريال سعودى</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className=" d-flex flex-row gap-5 justify-content-between p-2 mb-3">
                <p className="w-100">
                  {t("oldBalance")}: {dialogData.oldBalance} ريال
                </p>
                <p className="w-100">
                  {t("newBalance")}: {dialogData.newBalance} ريال
                </p>
              </div>

              <div className="row w-100 mt-4 align-items-center">
                <div className="col-6 mb-3">
                  <span className="font-weight-bold">ملاحظة : {dialogData.note}</span>
                </div>
                <div className="col-6 mb-3">
                  <span className="font-weight-bold underline-text">{dialogData.management}</span>
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
}
