import { Toaster } from "react-hot-toast";
import { createBrowserRouter, createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import Component from "./constants/Component";
import VenderContext from "./context/Store";
import "./style/App.scss";
import Error from "./Components/NotFound/Error";
import { CartProvider } from "./context/CartProvider";

function App() {
  function ProtectedRoutes({ children, requiredRoles }) {
    let token = localStorage.getItem("userToken");
    let userRole = localStorage.getItem("role");

    if (!token || !userRole) {
      return <Navigate to="/admin/login" replace={true} />;
    }

    // if (requiredRoles && !requiredRoles.includes(userRole)) {
    //   return <Error />;
    // }

    // Otherwise, render the children
    return <>{children}</>;
  }

  const root = createHashRouter([
    {
      path: "",
      element: <Component.Vendor />,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoutes>
              <Component.Dashboard />
            </ProtectedRoutes>
          )
        },
        {
          path: "/contact",
          element: (
            <ProtectedRoutes>
              <Component.Contact />
            </ProtectedRoutes>
          )
        },
        {
          path: "home",
          element: (
            <ProtectedRoutes>
              <Component.Home />
            </ProtectedRoutes>
          )
        },
        {
          path: "test",
          element: (
            <ProtectedRoutes>
              <Component.Test />
            </ProtectedRoutes>
          )
        },
        { path: "profile", element: <Component.Profile /> },
        {
          path: "*",
          element: <Component.Error />
        },

        //reports
        {
          path: "/reports/target-mobile-report",
          element: (
            <ProtectedRoutes requiredRoles={["Agent", "Distrebuter"]}>
              <Component.GetTargetMobile />
            </ProtectedRoutes>
          )
        },
        {
          path: "/reports/pos-transaction-logs",
          element: (
            <ProtectedRoutes requiredRoles={["SuperAdmin"]}>
              <Component.TransactionLogs />
            </ProtectedRoutes>
          )
        },
        {
          path: "/reports/pos-transaction-logs-agent",
          element: (
            <ProtectedRoutes requiredRoles={["Agent", "Distrebuter"]}>
              <Component.TransactionsLogsAgent />
            </ProtectedRoutes>
          )
        },
        {
          path: "/report/wholesale-bankcommision",
          element: (
            <ProtectedRoutes requiredRoles={["Agent", "Distrebuter"]}>
              <Component.wholesaleBankcommision />
            </ProtectedRoutes>
          )
        },
        {
          path: "/report/wholesale-bankcommision-distPrice",
          element: (
            <ProtectedRoutes requiredRoles={["SuperAdmin"]}>
              <Component.BankCommissionWithDistPrice />
            </ProtectedRoutes>
          )
        },
        {
          path: "/report/available-report",
          element: (
            <ProtectedRoutes requiredRoles={["SuperAdmin"]}>
              <Component.AvailableReport />
            </ProtectedRoutes>
          )
        },
        {
          path: "/report/transaction-logs-admin",
          element: (
            <ProtectedRoutes requiredRoles={["SuperAdmin"]}>
              <Component.TransactionsLogsAdmin />
            </ProtectedRoutes>
          )
        },
        {
          path: "/prices/prices-settings",
          element: (
            <ProtectedRoutes requiredRoles={["SuperAdmin", "Agent", "Distrebuter"]}>
              <Component.PricesSettings />
            </ProtectedRoutes>
          )
        },
        {
          path: "/prices/prices-view",
          element: (
            <ProtectedRoutes requiredRoles={["SuperAdmin", "Agent", "Distrebuter"]}>
              <Component.PriccesView />
            </ProtectedRoutes>
          )
        },
        //Bills
        {
          path: "/bills",
          element: (
            <ProtectedRoutes requiredRoles={["Distrebuter", "SuperAdmin", "Agent"]}>
              <Component.Bills />
            </ProtectedRoutes>
          )
        },

        //Customers
        {
          path: "/customers",
          element: (
            <ProtectedRoutes requiredRoles={["Agent", "Distrebuter", "SuperAdmin"]}>
              <Component.Customers />
            </ProtectedRoutes>
          )
        },

        {
          path: "/ViewBalanceTransaction",
          element: (
            <ProtectedRoutes>
              <Component.ViewBalanceTransaction />
            </ProtectedRoutes>
          )
        },
        {
          path: "/transactions",
          element: (
            <ProtectedRoutes requiredRoles={["Distrebuter", "SuperAdmin"]}>
              <Component.Transactions />
            </ProtectedRoutes>
          )
        },

        //POS
        {
          path: "/pos-total-report",
          element: (
            <ProtectedRoutes requiredRoles={["POS"]}>
              <Component.TotalReportPos />
            </ProtectedRoutes>
          )
        },
        {
          path: "/pos-detailed-report",
          element: (
            <ProtectedRoutes requiredRoles={["POS"]}>
              <Component.DetailedReportPos />
            </ProtectedRoutes>
          )
        },
        // {
        //   path: "/purchase-list",
        //   element: (
        //     <ProtectedRoutes requiredRoles={["POS"]}>
        //       <Component.PurchaseList />
        //     </ProtectedRoutes>
        //   )
        // },
        {
          path: "/Cart",
          element: (
            <ProtectedRoutes requiredRoles={["POS"]}>
              <Component.Cart />
            </ProtectedRoutes>
          )
        },
        {
          path: "/view-cart-products",
          element: (
            <ProtectedRoutes requiredRoles={["POS"]}>
              <Component.ViewCartProducts />
            </ProtectedRoutes>
          )
        },
        {
          path: "/pos-bills",
          element: (
            <ProtectedRoutes requiredRoles={["POS"]}>
              <Component.POSBills />
            </ProtectedRoutes>
          )
        },
        //ActionSetting
        {
          path: "/action-setting",
          element: (
            <ProtectedRoutes requiredRoles={["SuperAdmin"]}>
              <Component.ActionSetting />
            </ProtectedRoutes>
          )
        }
      ]
    },

    {
      path: "/admin/",
      element: <Component.Auth />,
      children: [
        { path: "login", element: <Component.Login /> },
        { path: "otp", element: <Component.Otp /> },
        { path: "forget-password", element: <Component.ForgetPassword /> },
        { path: "forget-password/otp", element: <Component.ForgetOTP /> },
        { path: "forget-password/reset", element: <Component.ResetPAssword /> },
        { path: "scan-qr-code", element: <Component.ScanQRCode /> }
      ]
    }
  ]);
  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: " Arial, Helvetica, sans-serif",
            textTransform: "capitalize",
            zIndex: "9999",
            // background: '#000',
            // color: '#fff',
            borderRadius: "10px",
            boxShadow: "10px 10px 10px rgba(188, 188, 188, 0.16)",
            background: "#fff",
            color: "#000"
          }
        }}
        containerStyle={{
          bottom: 50
        }}
      />

      <VenderContext>
        <CartProvider>
          <RouterProvider router={root} />
        </CartProvider>
      </VenderContext>
    </div>
  );
}

export default App;
