import React, { useContext, useEffect, useState } from "react";
import { BASE_URL } from "../../context/Enviroment";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { Col, Row, Spinner } from "react-bootstrap";
import "./PurchaseList.scss";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../context/CartProvider";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import { toast } from "react-hot-toast";

export default function PurchaseList({ addToCart: propAddToCart }) {
  const navigate = useNavigate();
  const { addToCart: contextAddToCart } = useContext(CartContext);
  const { addToCart } = useContext(CartContext);

  const { t } = useTranslation();
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allProducts, setProducts] = useState([]);
  const [warning, setWarning] = useState(false);

  const fetchData = async () => {
    let requestBody = {};
    try {
      setIsLoading(true);

      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      requestBody.pageNo = 1;
      requestBody.pageSize = 100000000;
      requestBody.brandId = 9;

      const response = await fetch(`${BASE_URL}/Product/GetPagedProducts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      console.log(data.Data);
      setProducts(data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const addToCartHandler = productId => {
    const isPresent = cartItems.some(item => item.productId === productId);
    if (isPresent) {
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 2000);
      return;
    }

    contextAddToCart(productId, 1);

    const selectedProduct = allProducts.find(product => product.ProductId === productId);

    if (selectedProduct) {
      const storedProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];

      const isProductInStorage = storedProducts.some(item => item.productId === productId);

      if (!isProductInStorage) {
        const updatedProducts = [
          ...storedProducts,
          {
            productId,
            name: selectedProduct.ProductName,
            price: selectedProduct.EndUserPrice,
            image: selectedProduct.Photo,
            quantity: 1
          }
        ];
        localStorage.setItem("cartProducts", JSON.stringify(updatedProducts));
      }
    }

    if (typeof propAddToCart === "function") {
      propAddToCart(productId);

      Swal.fire({
        position: "top-start",
        icon: "success",
        title: "Product Added To Cart",
        showConfirmButton: false,
        timer: 1500,
        width: "100px"
      });
    } else {
    }
  };

  useEffect(() => {
    console.log(typeof addToCart);
    fetchData();
  }, []);

  return (
    <div>
      <h3 className="text-main m-3 report-head">{t("products")}</h3>

      {isLoading && <Loading />}

      <Row className="w-100">
        {allProducts.map(product => (
          <Col key={product.ProductId} xs="12" md="3" sm="6" className="mb-3">
            <Card className={`h-100 card-content h-100 mb-2 ${!product.IsExistInStock ? "disabled-card" : ""}`}>
              <img src={product.Photo} alt={product.ProductName} style={{ width: "100%" }} className="pt-0 mt-0" />
              
              <div className="p-card-body">
                <h5 className="text-center">{product.ProductName}</h5>
              </div>
              <div className="d-flex justify-content-between">
                <span className="h6">سعر الكارت</span>
                <span className="h6 card-price">{product.EndUserPrice}</span>
              </div>
              <div>
                <button
                  className="mt-3 px-2 btn bg-add-btn text-white w-100 rounded-4 pb-1"
                  onClick={() => addToCartHandler(product.ProductId)}
                  disabled={!product.IsExistInStock}
                >
                  <i className="pi pi-plus-circle add-btn-card" /> {t("addToCart")}
                </button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      {warning && <div className="warning">Item is already added to your cart</div>}
    </div>
  );
}
