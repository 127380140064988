import React, { useEffect, useState } from "react";
import Loading from "../../Components/Loading/Loading";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import "./ActionSetting.scss";
import { BASE_URL } from "../../context/Enviroment";

export default function ActionSetting() {
  const [settings, setSettings] = useState(null);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [editSettings, setEditSettings] = useState(null);
  const { t, i18n } = useTranslation();
  const [direction, setDirection] = useState(localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr");

  const fetchData = async () => {
    const userToken = localStorage.getItem("userToken");

    try {
      const response = await fetch(`${BASE_URL}/ActionSetting/GetSettings`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });

      const data = await response.json();

      if (data.IsSuccess) {
        setSettings(data.Data);
      } else {
        // Handle the error
        console.error("Failed to fetch settings");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(
    () => {
      fetchData();
      const updateDirection = () => setDirection(i18n.language === "ar" ? "rtl" : "ltr");

      i18n.on("languageChanged", updateDirection);
      return () => i18n.off("languageChanged", updateDirection);
    },
    [i18n]
  );

  if (!settings) {
    return <Loading />;
  }

  //handle edit
  const handleEdit = currentSettings => {
    setEditSettings({ ...currentSettings });
    setDisplayDialog(true);
  };
  const handleUpdateSettings = async () => {
    await updateSettings(editSettings);
    setDisplayDialog(false);
  };

  //make edit request
  const updateSettings = async newSettings => {
    const userToken = localStorage.getItem("userToken");
    try {
   

      const updateData = { ...newSettings };

      const response = await fetch(`${BASE_URL}/ActionSetting/Update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(updateData) 
      });

      const data = await response.json();
      console.log("Update response:", data); 
      if (data.IsSuccess) {
        setSettings({ ...settings, ...newSettings }); 
        
      Swal.fire({
        position: "top-start",
        icon: "success",
        title: "Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      } else {
        console.error("Failed to update settings", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const dialogFooter = (
    <div className="text-center align-items-center">
      <Button
        label={t("save")}
        className="rounded-2"
        onClick={handleUpdateSettings}
        style={{ border: "none", backgroundColor: "#e58241 ", color: "#fff", direction: direction }}
      />
    </div>
  );

  const dialogHeader = (
    <div style={{ textAlign: "center" }} className="h3 text-main">
      {t("EditSettingsHeader")}
    </div>
  );
  return (
    <div className="card m-3 p-2 actionSetting-container" dir={direction}>
      <div className="table-responsive">
        <Table striped hover>
          <thead className="custom-thead">
            <tr>
              <th className="text-light">{t("stopPayment")}</th>
              <th className="text-light">{t("balanceChargeLimit")}</th>
              <th className="text-light"> {t("stopPriceEditing")}</th>
              <th className="text-light">{t("stopBalanceTransfer")}</th>
              <th className="text-light" />
            </tr>
          </thead>
          <tbody className="text-center">
            <tr>
              <td className="h6"> {settings.StopPayments ? t("yes") : t("no")}</td>
              <td className="h6"> {settings.BalanceChargeLimit}</td>
              <td className="h6"> {settings.StopPriceEditing ? t("yes") : t("no")}</td>
              <td className="h6"> {settings.StopBalanceTransfer ? t("yes") : t("no")}</td>
              <td>
                <Button
                  icon="pi pi-pencil"
                  rounded
                  outlined
                  className="mr-2 rounded-5 main-bg"
                  onClick={() => handleEdit(settings)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <Dialog
        className="action-dialog"
        style={{ width: "40vw", direction: direction }}
        header={dialogHeader}
        visible={displayDialog}
        onHide={() => setDisplayDialog(false)}
        footer={dialogFooter}
      >
        {" "}
        <div className="form-group d-flex justify-content-between px-5 mb-2">
          <label htmlFor="stopPayments" className="h5">
            {t("stopPayment")}
          </label>
          <Checkbox
            id="stopPayments"
            checked={editSettings?.StopPayments}
            onChange={e => setEditSettings({ ...editSettings, StopPayments: e.checked })}
          />
        </div>
        <div className="form-group d-flex justify-content-between px-5 mb-2">
          <label htmlFor="stopPriceEditing" className="h5">
            {t("stopPriceEditing")}{" "}
          </label>
          <Checkbox
            id="stopPriceEditing"
            className="d-flex "
            checked={editSettings?.StopPriceEditing}
            onChange={e => setEditSettings({ ...editSettings, StopPriceEditing: e.checked })}
          />
        </div>
        <div className="form-group d-flex justify-content-between px-5 mb-2 ">
          <label htmlFor="stopBalanceTransfer" className="h5">
            {t("stopBalanceTransfer")}{" "}
          </label>
          <Checkbox
            id="stopBalanceTransfer"
            checked={editSettings?.StopBalanceTransfer}
            onChange={e => setEditSettings({ ...editSettings, StopBalanceTransfer: e.checked })}
          />
        </div>
        <div className="form-group d-flex justify-content-between px-4 mb-2 mx-4">
          <label htmlFor="balanceChargeLimit" className="h5">
            {t("balanceChargeLimit")}{" "}
          </label>
          <InputNumber
            id="balanceChargeLimit"
            value={editSettings?.BalanceChargeLimit}
            onValueChange={e => setEditSettings({ ...editSettings, BalanceChargeLimit: e.value })}
          />
        </div>
      </Dialog>
    </div>
  );
}
