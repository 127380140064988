import React, { useState } from 'react';
import QRCode from 'qrcode.react';
const totp = require('totp-generator'); 

function TwoFactorAuth() {
  const [secret, setSecret] = useState('QDWSM3OYBPGTEVHPB5FKVDM3CSNCWHVK');
  const [enteredOTP, setEnteredOTP] = useState('');
  const [isOTPValid, setIsOTPValid] = useState(false);

  const verifyCode = () => {
    if (!secret) {
      //console.log('Please set a valid secret key.');
      return;
    }

    try {
      const otpValue = totp(secret); 
      //console.log('Generated OTP from Server:', otpValue);
      //console.log('Entered OTP:', enteredOTP);

      if (otpValue === enteredOTP) {
        //console.log('Code is valid!');
        setIsOTPValid(true);
      } else {
        //console.log('Code is invalid. Please try again.');
        setIsOTPValid(false);
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setIsOTPValid(false);
    }
  };

  return (
    <div>
      <h1>Google Authenticator 2FA</h1>
      <p>Secret Key: {secret}</p>
      <QRCode value={`otpauth://totp/MyApp?secret=${secret}`} />
      <input
        type="text"
        placeholder="Enter OTP"
        value={enteredOTP}
        onChange={(e) => setEnteredOTP(e.target.value)}
      />
      <button onClick={verifyCode}>Verify Code</button>
      {isOTPValid && <p>Code is valid!</p>}
      {!isOTPValid && <p>Code is invalid. Please try again.</p>}
    </div>
  );
}

export default TwoFactorAuth;
