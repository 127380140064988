import axios from "axios";
import { BASE_URL } from "../../../context/Enviroment";

//get Brands
export const getBrandsApi = async () => {
  try {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      console.error("User token not found in local storage");
      return [];
    }

    const response = await axios.get(`${BASE_URL}/Brand/GetBrands`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    });

    if (response.status === 200) {
      return response.data.Data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching brands:", error);
    throw error;
  }
};

//get Products
export const fetchProductsForBrandApi = async brandId => {
  try {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      console.error("User token not found in local storage");
      return [];
    }

    const requestBody = {
      brandId: String(brandId)
    };

    const response = await axios.post(`${BASE_URL}/Product/GetProductsSelectListFiltered`, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    });

    if (response.status === 200) {
      const data = response.data;
      const productOptions = data.map(product => ({
        label: product.Name,
        value: product.Id
      }));
      return productOptions;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

//get customers
export const getAllChildCustomersApi = async () => {
  try {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      console.error("User token not found in local storage");
      return [];
    }

    const requestBody = {
      isAll: true,
      pageNo: 1,
      pageSize: 10,
      haveParent: true
    };

    const response = await axios.post(`${BASE_URL}/CustomerAccount/GetCustomers`, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    });

    if (response.status === 200) {
      const data = response.data;
      return data.Data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching child customers:", error);
    throw error;
  }
};
//get users
export const getUsersApi = async () => {
  try {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      console.error("User token not found in local storage");
      return [];
    }

    const requestBody = {
      pageNo: 1,
      pageSize: 1000
    };

    const response = await axios.post(`${BASE_URL}/Users/GetUsers`, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    });

    if (response.status === 200) {
      const data = response.data;
      return data.Data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching child customers:", error);
    throw error;
  }
};

//add balance
export const addBalanceApi = async (amountValue, selectedCustomerId) => {
  try {
    const userToken = localStorage.getItem("userToken");
    const userRole = localStorage.getItem("role");
    const adminRole = localStorage.getItem("adminRole");

    if (!userToken) {
      console.error("User token not found in local storage");
      return;
    }

    const addBalanceBody = {
      amount: amountValue,
      customerId: selectedCustomerId,
      isAddition: true
    };

    let apiEndpoint = `${BASE_URL}/CustomerBalanceTransaction/AddBalanceDistributer`;

    if (adminRole === "SuperAdmin" ) {
      apiEndpoint = `${BASE_URL}/CustomerBalanceTransaction/GetBalanceTransactionAdmin`;
    }

    const response = await axios.post(apiEndpoint, addBalanceBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error adding balance:", error);
    throw error;
  }
};

//date formatting
function formatDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  return "";
}

//get customers transactions
export const getTransactionsApi = async (fromDate, toDate) => {
  try {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      console.error("User token not found in local storage");
      return [];
    }

    const requestBody = {
      pageNo: 1,
      pageSize: 1000,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate)
    };

    const response = await axios.post(`${BASE_URL}/CustomerBalanceTransaction/GetBalanceTransaction`, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    });

    if (response.status === 200) {
      const data = response.data;
      const filteredData = data.Data.filter(item => !item.IsAdminAction);
      return filteredData;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

//get All Prices Data
export const getAllPricesApi = async (selectedBrand, selectedChildId) => {
  try {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      console.error("User token not found in local storage");
      return [];
    }

    const pricesRequestBody = {
      brandId: selectedBrand.Id,
      childId: selectedChildId,
      isAll: true,
      parentId: null
    };

    const response = await axios.post(`${BASE_URL}/Prices/GetAllPrices`, pricesRequestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    });

    if (response.status === 200) {
      const data = response.data;
      return data.Data;
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching prices:", error);
    throw error;
  }
};
