import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import { Table, Pagination } from "react-bootstrap";
import { fetchProductsForBrandApi, getAllChildCustomersApi, getBrandsApi } from "../auth/Services/ContentAPIService";
import Loading from "../../Components/Loading/Loading";
import ExcelExporter from "../../ExcelExporter/ExcelExporter";
import PopupMessage from "../../Components/AlertPopup/Popup";
import CustomPagination from "../../Components/Paginator/Paginator";
import { BASE_URL } from "../../context/Enviroment";

export default function Bills() {
  const { t, i18n } = useTranslation();
  const [reportData, setReportData] = useState([]);
  const [billdDetails, setBilldDetails] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [billNumber, setBillNumber] = useState("");
  const [sentStatus, setSentStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  //Api request
  const fetchData = async () => {
    let requestBody = {};
    try {
      setIsLoading(true);

      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      if (fromDate && toDate) {
        requestBody.fromDate = formatDate(fromDate);
        requestBody.toDate = formatDate(toDate);
      }
      requestBody.pageNo = 1;
      requestBody.pageSize = 100000000;

      requestBody.billNumber = billNumber;
      requestBody.sentStatus = sentStatus;
      const response = await fetch(`${BASE_URL}/OrecalPridge/GetBillHeaders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setReportData(data.Data);

      //console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBillsByID = async rowData => {
    setSelectedRow(rowData);

    try {
      setIsLoading(true);

      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      const response = await fetch(`${BASE_URL}/OrecalPridge/GetBillDetails?id=${rowData.Id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setBilldDetails(data.Data);
      //console.log(data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  // handle pagination

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const billsData = reportData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(reportData.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle cancel click
  const handleCancelClick = () => {
    setFromDate("");
    setToDate("");
    setBillNumber("");
    setSentStatus("");
  };
  return (
    <div className="report-container">
      <h3 className="text-main m-3 report-head">{t("bills")}</h3>
      <div className="card container mb-4 custom-card m-3 p-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-column mb-3 w-100 ">
                <span className="h6 m-3">{t("from")}: </span>
                <div className="p-input-icon-left ">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar  rounded-2 p-2 w-100"
                    value={fromDate}
                    onChange={e => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-3 h6">{t("to")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar  rounded-2 p-2 w-100"
                    value={toDate}
                    onChange={e => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>
            </div>

            <div className=" d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-2 h6">{t("الحالة")} :</span>
                <select
                  className="tergetReport-dropdown rounded-2 w-100"
                  value={sentStatus}
                  onChange={e => setSentStatus(e.target.value)}
                >
                  <option value="">{t("choose")}</option>
                  <option value="1">{t("sentBills")}</option>
                  <option value="2">{t("unSentBills")}</option>
                </select>
              </div>
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-2 h6">{t("billNumber")} :</span>
                <input
                  className="tergetReport-dropdown rounded-2 p-2 w-100"
                  type="text"
                  placeholder={t("search")}
                  value={billNumber}
                  onChange={e => setBillNumber(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2"
                onClick={fetchData}
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff" }}
                className="rounded-2 cancel-btn w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3" />
        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <Table striped hover>
                <thead className="custom-thead">
                  <tr>
                    <th className="text-light">{t("billNumber")}</th>
                    <th className="text-light">{t("clientNumber")}</th>
                    <th className="text-light">{t("client")}</th>
                    {/* <th className="text-light">DISC_AMT</th> */}
                    <th className="text-light">{t("email")}</th>
                    <th className="text-light">{t("priceWithoutVAT")}</th>
                    <th className="text-light">{t("VAT_AMT")}</th>
                    <th className="text-light">{t("PAYED_AMT")}</th>
                    <th className="text-light">{t("date")}</th>
                    {/* <th className="text-light">BILL_NOTE</th> */}
                    {/* <th className="text-light">BILL_TIME</th> */}
                    <th className="text-light">{t("type")}</th>
                    <th className="text-light">{t("year")}</th>
                    {/* <th className="text-light">Cash No</th> */}
                    <th className="text-light">{t("SerialNumber")}</th>

                    {/* <th className="text-light">Id</th> */}
                    <th className="text-light">{t("ItemsCount")}</th>
                    <th className="text-light">{t("PaymentMethod")}</th>
                    {/* <th className="text-light">SentStatus</th> */}
                    {/* <th className="text-light">W_CODE</th> */}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {billsData?.map((rowData, index) => (
                    <tr key={index} onClick={() => getBillsByID(rowData)}>
                      <td>{rowData.BILL_NO}</td>
                      <td>{rowData.AD_U_ID}</td>
                      <td>{rowData.Customer_Name}</td>
                      {/* <td>{rowData.DISC_AMT}</td> */}
                      <td>{rowData.Email}</td>
                      <td>{parseFloat(rowData.BILL_AMT).toFixed(2)}</td>
                      <td>{parseFloat(rowData.VAT_AMT).toFixed(2)}</td>
                      <td>{rowData.PAYED_AMT}</td>
                      <td>{rowData.BILL_DATE}</td>
                      {/* <td>{rowData.BILL_NOTE}</td> */}
                      {/* <td>{rowData.BILL_TIME}</td> */}
                      <td>{rowData.BILL_TYPE == 1 ? "نقدي" : "اجل"}</td>
                      <td>{rowData.BRN_YEAR}</td>
                      {/* <td>{rowData.CASH_NO}</td> */}
                      <td>{rowData.Cust_Address}</td>

                      {/* <td>{rowData.Id}</td> */}
                      <td>{rowData.Items_Count}</td>
                      <td>
                        {rowData.PaymentMethod === 1 && "Wallet"}
                        {rowData.PaymentMethod === 2 && "CreditCard"}
                        {rowData.PaymentMethod === 3 && "ApplePay"}
                        {rowData.PaymentMethod === 4 && "NewLeap"}
                        {rowData.PaymentMethod === 5 && "SurePay"}
                        {rowData.PaymentMethod === 6 && "InterSoft"}
                      </td>
                      {/* <td>{rowData.SentStatus == 1 ? " تم الارسال" : "لم يتم الارسال"}</td> */}

                      {/* <td>{rowData.W_CODE}</td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfLastRow={indexOfLastRow}
                reportData={reportData}
              />
            </div>
          )}
        </div>
        <div className="table-responsive">
          {selectedRow && (
            <Table striped hover>
              <thead className="custom-thead">
                <tr>
                  {/* <th className="text-light">BILLID</th> */}
                  {/* <th className="text-light">BillHeader</th> */}
                  {/* <th className="text-light">DIS_AMT</th> */}
                  {/* <th className="text-light">DIS_PER</th> */}
                  {/* <th className="text-light">DetailsDate</th> */}
                  {/* <th className="text-light">FREE_QTY</th> */}
                  <th className="text-light">{t("type")}</th>
                  <th className="text-light">{t("barcode")}</th>
                  <th className="text-light">{t("price")}</th>
                  <th className="text-light">{t("quantity")}</th>
                  <th className="text-light">{t("note")}</th>
                  {/* <th className="text-light">P_SIZE</th> */}
                  {/* <th className="text-light">RCRD_NO</th> */}
                  <th className="text-light">{t("VAT_AMT")}</th>
                  <th className="text-light">{t("percentage")}</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {billdDetails.map((detail, index) => (
                  <tr key={index}>
                    {/* <td>{detail.BILLID}</td> */}
                    {/* <td>{detail.BillHeader}</td> */}
                    {/* <td>{detail.DIS_AMT}</td> */}
                    {/* <td>{detail.DIS_PER}</td> */}
                    {/* <td>{detail.DetailsDate}</td> */}
                    {/* <td>{detail.FREE_QTY}</td> */}
                    <td>{detail.ITM_UNT}</td>
                    <td>{detail.I_CODE}</td>
                    <td>{parseFloat(detail.I_PRICE).toFixed(2)}</td>
                    <td>{detail.I_QTY}</td>
                    <td>{detail.Note}</td>
                    {/* <td>{detail.P_SIZE}</td> */}
                    {/* <td>{detail.RCRD_NO}</td> */}
                    <td>{detail.VAT_AMT}</td>
                    <td>{detail.VAT_PER}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
}
