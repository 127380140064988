import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { Card } from "primereact/card";
import { Col, Row, Spinner } from "react-bootstrap";
import Loading from "../../../Components/Loading/Loading";
import CustomPagination from "../../../Components/Paginator/Paginator";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Table } from "react-bootstrap";
import { Image } from 'primereact/image';
import { BASE_URL } from "../../../context/Enviroment";

export default function POSBills() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [billsData, setBillsData] = useState([]);

  function formatDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
    return "";
  }
  const getBills = async () => {
    setIsLoading(true);

    const apiUrl = `${BASE_URL}/Report/GetUserSalesCard`;

    const requestData = {
      // pageNo: 1,
      // pageSize: 10000,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate)
    };

    const userToken = localStorage.getItem("userToken");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        console.error("Error fetching data. Status:", response.status);
        return;
      }

      const responseData = await response.json();
      console.log("API Response:", responseData);
      setBillsData(responseData.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log("Executing useEffect");
    getBills();
  }, []);

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentBills = billsData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(billsData.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle cancel click
  const handleCancelClick = () => {
    setFromDate("");
    setToDate("");
  };

  return (
    <>
      <div className="card p-3 m-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-row gap-3 mb-3 w-100 align-items-center">
                <span className="h6">{t("from")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={fromDate}
                    onChange={e => setFromDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>

              <div className="custom-dropdown d-flex flex-row gap-3 mb-3 w-100 align-items-center">
                <span className="ml-2 h6">{t("to")}: </span>
                <div className="p-input-icon-left">
                  <i className="pi pi-calendar calendar-icon text-main" />
                  <Calendar
                    className="tergetReport-calendar w-100"
                    value={toDate}
                    onChange={e => setToDate(e.value)}
                    dateFormat="dd/mm/yy"
                    placeholder={t("choose")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns px-4">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 mt-0">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn w-100 mb-2"
                onClick={getBills}
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff" }}
                className="rounded-2 cancel-btn w-100"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card m-4">
        <h3 className="text-main m-3 report-head">{t("bills")}</h3>

        {isLoading ? (
          <Loading />
        ) : (
          <div className="table-responsive">
            <Table striped hover>
              <thead className="custom-thead">
                <tr className="text-center">
                  <th className="text-light">{t("cardName")}</th>
                  <th className="text-light">{t("PinCard")}</th>
                  <th className="text-light">{t("SerialNumber")}</th>
                  <th className="text-light">{t("date")}</th>
                  <th className="text-light">{t("time")}</th>
                  <th className="text-light">{t("price")}</th>
                  <th className="text-light">{t("ExpirationDate")}</th>
                  <th className="text-light">{t("photo")}</th>
                </tr>
              </thead>
              <tbody>
                {currentBills.map((item, index) => (
                  <tr key={index}>
                    <td width="80px" className="text-center">
                      {item.NameEn}
                    </td>
                    <td width="80px" className="text-center">
                      {item.PinCard}
                    </td>

                    <td width="80px" className="text-center">
                      {item.SerialCard}
                    </td>

                    <td width="80px" className="text-center">
                      {format(new Date(item.Date), "dd/MM/yyyy")}
                    </td>
                    <td dir="ltr" width="80px" className="text-center">
                      {format(new Date(item.Date), "h:mm a")}
                    </td>
                    <td width="80px" className="text-center">
                      {item.Price}
                    </td>
                    <td width="80px" className="text-center">
                      {format(new Date(item.ExpirationDate), "dd/MM/yyyy")}

                    </td>
                    <td width="80px" className="text-center">
                      {/* <img width="80px" src={item.Photo} /> */}
                      <Image src={item.Photo} alt="Image" width="100" preview />

                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              indexOfLastRow={indexOfLastRow}
              reportData={billsData}
            />
          </div>
        )}
      </div>
    </>
  );
}
