import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Player } from "@lottiefiles/react-lottie-player";

const PopupMessage = ({ visible, onHide, header, message }) => {
  const [dialogVisible, setDialogVisible] = useState(visible);

  const handleCloseDialog = () => {
    setDialogVisible(false);
    onHide();
  };

  const handleDialogBlur = () => {
    handleCloseDialog();
  };

  useEffect(() => {
    setDialogVisible(visible);
  }, [visible]);

  return (
    <Dialog
      visible={dialogVisible}
      onHide={handleCloseDialog}
      closable={true}
      onBlur={handleDialogBlur}
      header={header}
      style={{ height: "500px", width: "600px" }}
    >
      <h4 className="text-center mb-2"> {message}</h4>
      <Player
        autoplay
        loop
        src="https://lottie.host/1f8afeb1-1b85-42df-827f-4854670150ac/Ci9NllXWLn.json"
        style={{ height: "300px", width: "300px" }}
      />
    </Dialog>
  );
};

export default PopupMessage;
