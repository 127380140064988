import React, { useRef, useState } from "react";
import SidebarLogo from "../../../assets/Images/logo/logo-dark.png";
import { useTranslation } from "react-i18next";
import forgetImg from "../../../assets/Images/login/forgot-password.png";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import "./ForgetPassword.scss";
import {  useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Loading from "../../../Components/Loading/Loading";
import { forgetPasswordApi } from "../Services/ApiService";
 
export default function ForgetPassword() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const toast = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const langChange = localStorage.getItem("langChange");

  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(151351);

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const isSuccess = await forgetPasswordApi(phoneNumber);

      if (isSuccess) {
        //console.log('Success');
        navigate('/admin/forget-password/otp', { state: { phoneNumber } });
      } else {
        //console.log('Error');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="row w-100 login  forget-password-container">
      <Toast ref={toast} position="bottom-center" />

      <div className="col-md-9  h-100  d-flex flex-column justify-content-center align-items-center ">
       
      {isLoading ? (
            <Loading />
          ) : (
        <div className="  d-flex flex-column justify-content-center align-items-center login-container ">
          <div
            dir={langChange == "en" ? "ltr" : "rtl"}
            className="d-flex flex-column text-center align-items-center gap-3 mb-4"
          >
            <img src={forgetImg} width="110px" height="120px" />
            <span className="h4">{t("forgetPasswordContent")}</span>
            <span />
          </div>
        
          <form className="mb-5 d-flex flex-column align-items-center">
            <div className="d-flex gap-2 mb-5">
              <div className="card flex justify-content-center">
                <span className="p-float-label ">
                  <input
                    type="email"
                    className="form-control form-control-lg forget-input"
                    id="number-input"
                    aria-describedby="emailHelp"
                    onChange={e => setPhoneNumber(e.target.value)}
                  />

                  <label htmlFor="number-input">Mobile Number</label>
                </span>
              </div>
            </div>

            <Button className="rounded-3 login-btn justify-content-center" severity="info" type="submit" onClick={handleSubmit}>
              {isLoading ? (
                <ProgressSpinner style={{ width: "30px", height: "30px" }} strokeWidth="6" animationDuration=".5s" />
              ) : (
                t("otpBtn")
              )}
            </Button>
          </form>

        </div>

)}

      </div>
      <div className="col-md-3 h-100 d-flex text-center justify-content-center align-items-center login-sidebar">
        <img src={SidebarLogo} width="220px" height="110px" />
      </div>
    </div>
  );
}
