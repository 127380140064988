import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../context/Enviroment";
import { fetchProductsForBrandApi, getBrandsApi } from "../../auth/Services/ContentAPIService";
import PopupMessage from "../../../Components/AlertPopup/Popup";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Table, Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../../Components/Loading/Loading";
import CustomPagination from "../../../Components/Paginator/Paginator";
import ExcelExporter from "../../../ExcelExporter/ExcelExporter";

export default function AvailableReport() {
  const [reportData, setReportData] = useState([]);
  const options = [{ label: "اجمالي", value: "اجمالي" }, { label: "تفصيلي", value: "تفصيلي" }];
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedBrandName, setSelectedBrandName] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const { t, i18n } = useTranslation();

  const [brands, setBrands] = useState();

  const getBrands = async () => {
    setIsLoading(true);

    try {
      const brandData = await getBrandsApi();
      setBrands(brandData);
    } catch (error) {
      console.error("An error occurred while fetching brands:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // get all products
  const [products, setProducts] = useState();
  const fetchProductsForBrand = async brandId => {
    try {
      const productOptions = await fetchProductsForBrandApi(brandId);
      setProducts(productOptions);
    } catch (error) {
      console.error("An error occurred while fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async selectedOptionValue => {
    setIsLoading(true);

    try {
      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        console.error("User token not found in local storage");
        return;
      }

      const reportType = selectedOptionValue === "اجمالي" ? 1 : 2;

      const requestBody = {
        reportType: reportType,
        productId: selectedProduct || null
      };

      if (selectedBrand && selectedBrand.Id) {
        requestBody.brandId = selectedBrand.Id;
      }

      const response = await fetch(`${BASE_URL}/Report/GetReportAvailable`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setReportData(data.Data);
      //console.log(data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionChange = e => {
    setSelectedOption(e.value);
  };

  useEffect(() => {
    getBrands();
  }, []);

  const [buttonClicked, setButtonClicked] = useState(false);

  const handleSearchClick = () => {
    if (selectedOption === null) {
      setShowPopup(true);
    } else {
      setButtonClicked(true);
      fetchData(selectedOption);
    }
  };
  const onHide = () => {
    setShowPopup(false);
  };

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const availableReport = reportData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(reportData.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  //handle excel sheet to export
  const columnsToExport = reportData.map(item => ({
    [t("TotalCost")]: item.TotalCost,
    [t("TotalSellPrice")]: item.TotalSellPrice,
    [t("TotalCount")]: item.TotalCount
  }));

  const handleBrandChange = e => {
    const selectedBrandId = e.value?.Id;
    const selectedBrandName = e.value?.Name;
    setSelectedBrand(selectedBrandId);
    setSelectedBrandName(selectedBrandName);

    if (selectedBrandId !== undefined) {
      fetchProductsForBrand(selectedBrandId);
    }
  };

  //handle cancel click
  const handleCancelClick = () => {
    setSelectedBrandName("");
    setSelectedOption("");
    setSelectedProduct("");
  };

  return (
    <div className="report-container ">
      <h3 className="text-main m-3 report-head">{t("availableReport")}</h3>

      <div className="card  mb-4 custom-card m-3 p-4">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-2 h6">{t("reportType")}: </span>
                <Dropdown
                  className="tergetReport-dropdown w-100"
                  value={selectedOption}
                  options={options}
                  onChange={handleOptionChange}
                  placeholder={t("choose")}
                />
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-2 h6">{t("selectBrand")} :</span>
                <Dropdown
                  className="tergetReport-dropdown w-100"
                  value={selectedBrand}
                  options={brands}
                  onChange={handleBrandChange}
                  optionLabel="Name"
                  placeholder={t("choose")}
                  valueTemplate={option => <span>{selectedBrandName}</span>}
                />
              </div>

              <div className="custom-dropdown d-flex flex-column mb-3 w-100">
                <span className="m-2 h6">{t("selectProduct")} : </span>
                <Dropdown
                  className="tergetReport-dropdown w-100"
                  value={selectedProduct}
                  options={products}
                  onChange={e => setSelectedProduct(e.value)}
                  optionLabel="label"
                  placeholder={t("choose")}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mt-3 mt-md-0 report-btns">
            <div className="targetBtn-container d-flex flex-row p-2 gap-3 mb-2">
              <Button
                label={t("search")}
                style={{ border: "none", backgroundColor: "#007bff", color: "#fff" }}
                className="rounded-2 btn"
                onClick={handleSearchClick}
              />
              <Button
                onClick={handleCancelClick}
                label={t("cancel")}
                style={{ border: "none", backgroundColor: "#65539a", color: "#fff", width: "117px", height: "50px" }}
                className="rounded-2"
              />
            </div>
          </div>
        </div>
      </div>

      <PopupMessage visible={showPopup} onHide={onHide} message={t("selectOptionMessagePopup")} closable={true} onBlur={true} />

      <div className="card m-3">
        <div className="d-flex justify-content-between align-items-center p-3">
          <ExcelExporter data={columnsToExport} fileName="report_data" />
        </div>

        <div className="mb-4">
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className="table-responsive p-2">
                <Table striped hover>
                  <thead className="custom-thead">
                    <tr>
                      {buttonClicked && selectedOption === "تفصيلي" && <th className="text-light">{t("ProductName")}</th>}
                      <th className="text-light">{t("TotalCost")}</th>
                      <th className="text-light">{t("sellPrice")}</th>
                      <th className="text-light">{t("TotalCount")}</th>
                    </tr>
                  </thead>
                  <tbody className="text-center transactionlogs-table">
                    {availableReport.map((item, index) => (
                      <tr key={index}>
                        {buttonClicked && selectedOption === "تفصيلي" && (
                          <td width="100px" className="ml-4">
                            {item.ProductName}
                          </td>
                        )}
                        <td width="100px" className="ml-4">
                          {item.TotalCost}
                        </td>
                        <td width="100px" className="ml-4">
                          {item.TotalSellPrice}
                        </td>
                        <td width="100px" className="ml-4">
                          {item.TotalCount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  indexOfLastRow={indexOfLastRow}
                  reportData={reportData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
