import axios from 'axios';
import { BASE_URL } from '../../../context/Enviroment';



//Forget Password API

export const forgetPasswordApi = async (phoneNumber) => {
  try {
    const response = await axios.get(`${BASE_URL}/Auth/ForgetPassword`, {
      params: {
        phoneNumber: phoneNumber,
      },
    });

    if (response.data.IsSuccess === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
};