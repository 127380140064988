import LogoSvg from "../../assets/svg/LogoSvg";
import { BiCartAlt, BiAlignLeft, BiMoney, BiCard,BiCreditCardFront } from "react-icons/bi";

const routes = [
  {
    path: "",
    nameEn: "Dashboard",
    nameAr: "الصفحة الرئيسية",
    icon: <LogoSvg.Dashboard className="logoSvg" style={{ width: 17 }} />,
    roles: ["Distrebuter", "SuperAdmin", "Accountants", "CustomerBalance"]
  },
  {
    path: "/Reports",
    nameEn: "Reports",
    nameAr: "التقارير",
    icon: <BiAlignLeft className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/reports/target-mobile-report",
        nameEn: "Target Report",
        nameAr: "العملاء",
        roles: ["Agent", "Distrebuter"],
        adminRole: ["Agent", "Distrebuter"]
      },
      {
        path: "/reports/pos-transaction-logs",
        nameEn: "Transaction Logs ",
        nameAr: " سجلات معاملات نقاط البيع",
        roles: ["SuperAdmin"],
        adminRole: ["SuperAdmin"]
      },
      {
        path: "/reports/pos-transaction-logs-agent",
        nameEn: "Transaction Logs ",
        nameAr: " حركة الحساب",
        roles: ["Agent", "Distrebuter"],
        adminRole: ["Agent", "Distrebuter"]
      },
      {
        path: "/report/wholesale-bankcommision-distPrice",
        nameEn: "wholesale Bankcommision ",
        nameAr: "   البيع بعمولة البنك",
        roles: ["SuperAdmin"],
        adminRole: ["SuperAdmin"]
      },
      {
        path: "/report/available-report",
        nameEn: "Available Report ",
        nameAr: " تقارير  المتاح",
        roles: ["SuperAdmin"],
        adminRole: ["SuperAdmin"]
      },
      {
        path: "/report/transaction-logs-admin",
        nameEn: "TransactionLogs-Admin",
        nameAr: " كشف حركة العميل",
        roles: ["SuperAdmin"],
        adminRole: ["SuperAdmin"]
      }
    ]
  },

  {
    nameEn: "Bills",
    nameAr: "الفواتير",
    icon: <BiCard className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/bills",
        nameEn: "Bills",
        nameAr: "الفواتير",
        roles: ["Distrebuter", "SuperAdmin", "Agent"],
        adminRole: ["SuperAdmin", "Agent"]
      }
    ]
  },

  {
    nameEn: "Customers",
    nameAr: " إدارة المستخدمين",
    icon: <BiCartAlt className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/customers",
        nameEn: "Customers Settings",
        nameAr: "إدارة العملاء",
        roles: ["Agent", "Distrebuter", "SuperAdmin"],
        adminRole: ["SuperAdmin", "Agent"]
      },
      {
        path: "/transactions",
        nameEn: "Customers Transactions",
        nameAr: "تحويلات العملاء",
        roles: ["Distrebuter", "SuperAdmin"],
        adminRole: ["SuperAdmin"]
      }
    ]
  },
  {
    path: "/Prices",
    nameEn: "Prices",
    nameAr: "الأسعار",
    icon: <BiMoney className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/prices/prices-view",
        nameEn: "Prices View",
        nameAr: "عرض الأسعار",
        roles: ["SuperAdmin", "Agent", "Distrebuter"],
        adminRole: ["SuperAdmin", "Agent"]
      },
      {
        path: "/prices/prices-settings",
        nameEn: "Prices Settings",
        nameAr: "اعدادات الأسعار",
        roles: ["SuperAdmin", "Agent", "Distrebuter"],
        adminRole: ["SuperAdmin", "Agent"]
      }
    ]
  },
 
 
  {
    path: "/reports",
    nameEn: "Reports",
    nameAr: "التقارير",
    icon: <BiMoney className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/pos-total-report",
        nameEn: "Total Report",
        nameAr: "التقرير الاجمالي",
        roles: ["POS"],
        adminRole: ["POS"],
      },
      {
        path: "/pos-detailed-report",
        nameEn: "Detailed Report",
        nameAr: "التقرير التفصيلي",
        roles: ["POS"],
        adminRole: ["POS"],
      },
      // {
      //   path: "/reports/pos-transaction-logs-agent",
      //   nameEn: "Transaction Logs ",
      //   nameAr: " حركة الحساب",
      //   roles: ["POS"],
      //   adminRole: ["POS"],
      // },
      {
        path: "/transactions",
        nameEn: "Customers Transactions",
        nameAr: "تحويلات العملاء",
        roles: ["POS"],
        adminRole: ["POS"],
      }
    ]
  },
  {
  
    // {
    //   path: "/purchase-list",
    //   nameEn: "Purchase List",
    //   nameAr: "قائمة المشتريات",
    //   roles: ["POS"],
    //   adminRole: ["POS"]
    // },
    
      path: "/pos-bills",
      nameEn: "POS Bills",
      nameAr: "كروتي",
      roles: ["POS"],
      adminRole: ["POS"],
      icon: <BiCreditCardFront className="logoSvg" style={{ width: 17 }} />,

 
},
  {
    path: "/action",
    nameEn: "Action Setting",
    nameAr: "اعدادات النظام",
    icon: <BiMoney className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/action-setting",
        nameEn: "Action Setting",
        nameAr: " الاعدادات",
        roles: ["SuperAdmin"],
        adminRole: ["SuperAdmin"]
      }
    ]
  }
];

export default routes;
